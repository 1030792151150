import React, { useMemo } from "react";
import { useAuth } from "contexts/auth";

export function useQuotePolicy({ quote }) {
  const { userRole } = useAuth();

  const canCreate = useMemo(() => {
    return typeof userRole !== "undefined";
  }, [userRole]);

  const permittedAttributesForUpdate = useMemo(() => {
    if (quote.status === "sent") {
      return ["internal_notes"];
    } else {
      return [
        "status",
        "tagged",
        "customer_id",
        "freight_charge",
        "job_id",
        "order_no",
        "public_notes",
        "internal_notes",
        "quote_date",
      ];
    }
  }, [userRole, quote]);

  const permittedAttributesForCreate = useMemo(() => {
    return [
      "status",
      "tagged",
      "customer_id",
      "job_id",
      "order_no",
      "freight_charge",
      "public_notes",
      "internal_notes",
      "quote_date",
    ];
  }, [userRole, quote]);

  return {
    canCreate,
    permittedAttributesForUpdate,
    permittedAttributesForCreate,
  };
}
