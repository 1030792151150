import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

function QuoteNotes({ validateFieldIsEditable }) {
  return (
    <div className="card card-top card-top-big px-5 py-5">
      <div className="form-row">
        <div className="col-md-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="public_notes">
              Customer Notes (Seen by Customer)
            </label>
            <Field
              name="public_notes"
              cols="30"
              rows="8"
              className="form-control"
              as="textarea"
              disabled={!validateFieldIsEditable("public_notes")}
            />
          </div>
        </div>
        <div className="col-md-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="internal_notes">
              Internal Notes (Only visible to staff)
            </label>
            <Field
              name="internal_notes"
              cols="30"
              rows="8"
              className="form-control"
              as="textarea"
              disabled={!validateFieldIsEditable("internal_notes")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

QuoteNotes.propTypes = {
  validateFieldIsEditable: PropTypes.func.isRequired,
};

export default QuoteNotes;
