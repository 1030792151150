import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import { find, some } from "lodash";
import { useLocation } from "react-router-dom";

import { useJobs } from "hooks/useJobs";
import { useCustomers } from "hooks/useCustomers";

import Input from "components/Formik/Input";
import DropdownInput from "components/Formik/DropdownInput";
import DatePickerInput from "components/Formik/DatePickerInput";
import FileUploads from "components/FileUploads";

function QuoteOverview({
  afterFileDelete,
  validateFieldIsEditable,
  hasFilesToPurge,
}) {
  const { jobs, status: jobsFetchStatus } = useJobs();
  const { customers } = useCustomers();
  const location = useLocation();

  const statusOptions = [
    { value: "draft", label: "Draft" },
    { value: "sent", label: "Sent" },
  ];

  const freightChargeOptions = [
    {
      label: "Delivery - $110",
      value: { description: "Delivery", cost: "110.0" },
    },
    {
      label: "Customer Pickup",
      value: { description: "Customer Pickup", cost: "0.0" },
    },
  ];

  const jobOptions = useMemo(() => {
    return jobs.map((job) => {
      return {
        value: job.id,
        label: job.name,
        address_line_1: job.address.line_1,
      };
    });
  }, [jobs]);

  const customerOptions = useMemo(() => {
    return customers.map((customer) => {
      return { value: customer.id, label: customer.company_name };
    });
  }, [customers]);

  const {
    values: { job_id, files },
    setFieldValue,
  } = useFormikContext();

  const hasUnsavedFileChanges = useMemo(() => {
    return (
      some(files, (file) => typeof file.id === "undefined") || hasFilesToPurge
    );
  }, [files, hasFilesToPurge]);

  function filterJobsWithAddress(candidate, input) {
    const label = candidate.data.label.toLowerCase();
    const filterableAddress = candidate.data.address_line_1.toLowerCase();
    const filterableInput = input.toLowerCase();

    if (input === "") return true;
    if (label.startsWith(filterableInput)) return true;
    if (filterableAddress.startsWith(filterableInput)) return true;
    return false;
  }

  function getJobAddressById(id) {
    const job = find(jobs, (job) => job.id === id);
    if (typeof job === "undefined") return "";
    if (typeof job.address === "undefined") return "No address specified";

    return `${job.address.line_1 || ""}, ${job.address.line_2 || ""}, ${
      job.address.city || ""
    }, ${job.address.post_code || ""}`;
  }

  useEffect(() => {
    if (jobsFetchStatus !== "loading") {
      setFieldValue("_jobAddress", getJobAddressById(job_id));
    }
  }, [job_id, jobsFetchStatus]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const jobId = searchParams.get("jobId");
    if (jobId) {
      setFieldValue("job_id", Number(jobId));
    }
  }, []);

  return (
    <div className="card card-top card-top-big px-5 py-5">
      <div className="form-row">
        <div className="col-md-3 col-lg-3 col-sm-3">
          <div className="form-group">
            <label htmlFor="order_no">Order No</label>

            <Field
              name="order_no"
              className="form-control"
              placeholder="Order Number"
              component={Input}
              disabled={!validateFieldIsEditable("order_no")}
            />
          </div>
        </div>
        <div className="col-md-3 col-lg-3 col-sm-3">
          <div className="form-group">
            <label htmlFor="job_id">Job Name</label>

            <Field
              name="job_id"
              placeholder="Job Name"
              component={DropdownInput}
              options={jobOptions}
              customFilter={filterJobsWithAddress}
              as="select"
              disabled={!validateFieldIsEditable("job_id")}
            >
              <option value=""></option>
              {jobs.map((job) => {
                return (
                  <option value={job.id} key={job.id}>
                    {job.name}
                  </option>
                );
              })}
            </Field>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6">
          <div className="form-group">
            <label htmlFor="job-address">Job Address</label>
            <Field
              name="_jobAddress"
              className="form-control"
              component={Input}
              disabled
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-12 col-md-6 col-lg-6 px-0">
          <div className="form-row mt-0">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="form-group">
                <label htmlFor="quote_no">Quote No</label>
                <Field
                  name="quote_no"
                  options={statusOptions}
                  component={Input}
                  disabled
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="form-group">
                <label htmlFor="quote_date">Date</label>
                <Field
                  name="quote_date"
                  placeholder="Date"
                  component={DatePickerInput}
                  disabled={!validateFieldIsEditable("quote_date")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-6 px-0">
          <div className="form-row mt-0">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="form-group">
                <label htmlFor="status">Status</label>
                <Field
                  name="status"
                  options={statusOptions}
                  component={DropdownInput}
                  disabled={!validateFieldIsEditable("status")}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="form-group">
                <label htmlFor="sales-person">Sales Person</label>
                <Field
                  name="_salesPerson"
                  className="form-control"
                  component={Input}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3 col-lg-3">
          <div className="form-group">
            <label htmlFor="customer_id">Customer</label>
            <Field
              name="customer_id"
              placeholder="Customer"
              component={DropdownInput}
              options={customerOptions}
              as="select"
              disabled={!validateFieldIsEditable("customer_id")}
            >
              <option value=""></option>
              {customers.map((customer) => {
                return (
                  <option value={customer.id} key={customer.id}>
                    {customer.company_name}
                  </option>
                );
              })}
            </Field>
          </div>
        </div>
        <div className="col-md-3 col-lg-3">
          <div className="form-group">
            <label htmlFor="tagged">Tagged Quote</label>
            <div className="select-box">
              <Field
                name="tagged"
                as="select"
                disabled={!validateFieldIsEditable("tagged")}
              >
                <option value="true">True</option>
                <option value="false">False</option>
              </Field>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6">
          <div className="form-group">
            <label>Freight Charge</label>
            <Field
              name="freight_charge"
              placeholder="Freight Charge"
              component={DropdownInput}
              options={freightChargeOptions}
              as="select"
              disabled={!validateFieldIsEditable("freight_charge")}
            >
              <option value=""></option>
              {customers.map((customer) => {
                return (
                  <option value={customer.id} key={customer.id}>
                    {customer.company_name}
                  </option>
                );
              })}
            </Field>
          </div>
        </div>
      </div>
      <FileUploads
        afterFileDelete={afterFileDelete}
        hasUnsavedFileChanges={hasUnsavedFileChanges}
      />
    </div>
  );
}

QuoteOverview.propTypes = {
  validateFieldIsEditable: PropTypes.func.isRequired,
  hasFilesToPurge: PropTypes.bool.isRequired,
  afterFileDelete: PropTypes.func,
};

export default QuoteOverview;
