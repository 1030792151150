import React, { useMemo } from "react";
import { useQuery } from "react-query";

import { getDoorConfigOptions } from "utils/horizon-api-client";

export function useDoorConfigOptions() {
  const { data, status } = useQuery("doorConfigOptions", fetchOptions);
  const optionsData = useMemo(parseOptionsData, [data]);

  async function fetchOptions(key) {
    const response = await getDoorConfigOptions();
    return response.data;
  }

  function parseOptionsData() {
    if (typeof data !== "undefined") {
      return {
        clashingTimbers: data.data.clashing_timbers,
        coreTypes: data.data.core_types,
        meetingStiles: data.data.meeting_stiles,
        skinCodes: data.data.skin_codes,
        visionPanels: data.data.vision_panels,
        grilleOpenings: data.data.grille_openings,
        resorcinolGlues: data.data.resorcinol_glues,
        doorExtras: data.data.door_extras,

        doorJambTimbers: data.data.door_jamb_timbers,
        doorJambFacings: data.data.door_jamb_facings,
        doorJambHandings: data.data.door_jamb_handings,
        doorJambProfiles: data.data.door_jamb_profiles,
        doorJambSills: data.data.door_jamb_sills,
        doorJambFloorClearances: data.data.door_jamb_floor_clearances,
        doorJambMachiningCodes: data.data.door_jamb_machining_codes,
        doorStops: data.data.door_stops,

        doorDetails: data.data.door_details,
        hinges: data.data.hinges,
      };
    } else {
      return {
        clashingTimbers: [],
        coreTypes: [],
        meetingStiles: [],
        skinCodes: [],
        visionPanels: [],
        resorcinolGlues: [],
        doorExtras: [],
        grilleOpenings: [],
        doorJambTimbers: [],
        doorJambFacings: [],
        doorJambHandings: [],
        doorJambProfiles: [],
        doorJambSills: [],
        doorJambFloorClearances: [],
        doorJambMachiningCodes: [],
        doorStops: [],
        doorDetails: [],
        hinges: [],
      };
    }
  }

  return {
    ...optionsData,
    status,
  };
}
