import React, { useRef } from "react";
import PropTypes from "prop-types";

function EditableCell({
  value: initialValue,
  row: { original },
  column: { id },
  onDataUpdate,
}) {
  const [value, setValue] = React.useState(initialValue);
  const [prevValue, setPrevValue] = React.useState(initialValue);
  const cellInput = useRef();

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      cellInput.current.blur();
    }
  };

  const onBlur = () => {
    handleDataUpdate();
  };

  const handleDataUpdate = () => {
    if (value !== prevValue) {
      onDataUpdate({
        rowData: original,
        newValue: value,
      });
      setPrevValue(value);
    }
  };

  React.useEffect(() => {
    setValue(initialValue);
    setPrevValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    setValue(initialValue);
    setPrevValue(initialValue);
  }, [original]);

  if (original.parent_id !== null) {
    return null;
  }

  return (
    <input
      ref={cellInput}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      style={{ border: 0, width: "30px" }}
    />
  );
}

EditableCell.propTypes = {
  value: PropTypes.number.isRequired,
  row: PropTypes.shape({
    original: PropTypes.object,
  }),
  column: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  onDataUpdate: PropTypes.func,
};

export default EditableCell;
