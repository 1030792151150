import React, { useMemo, useCallback } from "react";
import Header from "components/Header";
import TableHeader from "components/TableHeader";
import IndexTable from "components/IndexTable";
import IndexTableWrapper from "components/IndexTableWrapper";

import { useJobs } from "hooks/useJobs";

function Jobs() {
  const {
    fetchMore,
    canFetchMore,
    jobs,
    totalJobs,
    status,
    setSearchTerm,
    searchFieldValue,
    searchOptions,
  } = useJobs();

  const sortOnStatus = useCallback((rowA, rowB, columnId) => {
    const rowAValue = rowA.values.status.props.status;
    const rowBValue = rowB.values.status.props.status;
    if (rowAValue > rowBValue) return 1;
    if (rowAValue < rowBValue) return -1;
    return 0;
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Job Name ",
        accessor: "name",
      },
      {
        Header: "Description ",
        accessor: "description",
        className: "v-sm-visible",
      },
      {
        Header: "Status ",
        accessor: "status",
        className: "v-sm-visible",
        sortType: sortOnStatus,
      },
      {
        Header: "Required Date ",
        accessor: "required_before",
        className: "v-sm-visible required-date",
      },
      {
        Header: "End Customer ",
        accessor: "end_customer",
      },
      {
        Header: "Account Email ",
        accessor: "account_email",
        className: "v-sm-visible",
      },
      {
        Header: "",
        accessor: "extras",
        disableSortBy: true,
      },
    ],
    []
  );

  const searchHelperOptions = useMemo(() => {
    const opts = [];
    Object.keys(searchOptions).forEach((optionName) => {
      const option = searchOptions[optionName];
      if (typeof option.displayName !== "undefined") {
        opts.push({
          displayName: option.displayName,
          defaultSearchTerm: option.defaultSearchTerm,
        });
      }
    });
    return opts;
  }, []);

  return (
    <>
      <Header pageTitle="Jobs" />
      <IndexTableWrapper
        onScrollToBottom={fetchMore}
        canFetchMore={
          typeof canFetchMore === "undefined" ? false : canFetchMore
        }
      >
        <TableHeader
          setSearchTerm={setSearchTerm}
          searchFieldValue={searchFieldValue}
          searchHelperOptions={searchHelperOptions}
          resourceName="Job"
        />
        <IndexTable columns={columns} data={jobs} status={status} />
        <span className="table-footer-label">
          Showing {jobs.length} of {totalJobs} jobs
        </span>
      </IndexTableWrapper>
    </>
  );
}

export default Jobs;
