import React, { useMemo } from "react";
import PropTypes from "prop-types";

import DoorsTableHeader from "components/DoorsList/DoorsTableHeader";
import IndexTable from "components/IndexTable";
import IndexTableWrapper from "components/IndexTableWrapper";
import EditableCell from "components/EditableCell";
import ChildToggleCell from "components/DoorsList/ChildToggleCell";

import { updateDoorPosition } from "utils/horizon-api-client";
import { toast } from "react-toastify";

import "assets/stylesheets/doorsList.css";

import { useDoors } from "hooks/useDoors";

function DoorsList({ quoteId }) {
  const {
    refetch,
    doors,
    totalDoors,
    searchOptions,
    status,
    setSearchTerm,
    searchFieldValue,
  } = useDoors({ quoteId });

  const columns = useMemo(
    () => [
      {
        Header: "Duplicates ",
        id: "expander",
        Cell: ChildToggleCell,
      },
      {
        Header: "Sort ",
        Cell: EditableCell,
        accessor: "position",
        className: "v-sm-visible",
      },
      {
        Header: "Serial ",
        accessor: "serial",
        className: "v-sm-visible",
      },
      {
        Header: "Height ",
        accessor: "height",
        className: "v-sm-visible",
      },
      {
        Header: "Width ",
        accessor: "width",
        className: "v-sm-visible",
      },
      {
        Header: "Description ",
        accessor: "description",
        className: "v-sm-visible",
      },
      {
        Header: "Reference ",
        accessor: "reference",
      },
      {
        Header: "Unit Sell Price ",
        accessor: "sell_price",
      },
      {
        Header: "",
        accessor: "dropdown",
        disableSortBy: true,
      },
    ],
    []
  );

  async function onDataUpdate({ rowData, newValue }) {
    const response = await updateDoorPosition({
      id: rowData.id,
      newPosition: newValue,
    });
    sendNotification(response);
    refetch();
  }

  function sendNotification(response) {
    switch (response.status) {
      case 200:
        toast.success("Updated!");
        break;
      case 403:
        toast.error("You do not have permissions to update this door");
        break;
      case 500:
        toast.error("Internal server error.");
        break;
      default:
        toast.error("Error updating door position");
        break;
    }
  }

  function isSubRow(row) {
    if (row.original.parent_id !== null) {
      return true;
    } else {
      return false;
    }
  }

  const searchHelperOptions = useMemo(() => {
    const opts = [];
    Object.keys(searchOptions).forEach((optionName) => {
      const option = searchOptions[optionName];
      if (typeof option.displayName !== "undefined") {
        opts.push({
          displayName: option.displayName,
          defaultSearchTerm: option.defaultSearchTerm,
        });
      }
    });
    return opts;
  }, []);

  return (
    <>
      <div id="doors-list" className="card-top-big content-main-small">
        <IndexTableWrapper>
          <DoorsTableHeader
            setSearchTerm={setSearchTerm}
            searchFieldValue={searchFieldValue}
            searchHelperOptions={searchHelperOptions}
            resourceName="Door"
          />
          <IndexTable
            columns={columns}
            data={doors}
            status={status}
            onDataUpdate={onDataUpdate}
            rowProps={(row) => ({
              className: isSubRow(row) ? "sub-row" : "",
            })}
          />
          <span className="table-footer-label">
            Showing {doors.length} of {totalDoors} doors
          </span>
        </IndexTableWrapper>
      </div>
    </>
  );
}

DoorsList.propTypes = {
  quoteId: PropTypes.number.isRequired,
};

export default DoorsList;
