import React, { useMemo, useRef } from "react";
import { Field, useFormikContext } from "formik";

import DropdownInput from "components/Formik/DropdownInput";
import EditableDropdownInput from "components/Formik/EditableDropdownInput";
import Input from "components/Formik/Input";
import PricingOverview from "components/DoorForm/PricingOverview";

import { withBlankOption } from "utils/form-utils";

import { useDoorConfigOptions } from "hooks/useDoorConfigOptions";
import { useDoor } from "hooks/useDoor";
import { useParams } from "react-router-dom";

function DoorDetails() {
  const {
    clashingTimbers,
    coreTypes,
    meetingStiles,
    skinCodes,
    doorDetails,
  } = useDoorConfigOptions();

  const { setFieldValue } = useFormikContext();

  const pairWidth = useRef();
  const { id } = useParams();
  const { data } = useDoor({ id: id });

  const nextFieldOnSlash = (e, field) => {
    if (e.nativeEvent.data === "/") {
      setFieldValue(field.name, field.value);
      setFieldValue("width_pair", "");
      pairWidth.current.focus();
    }
  };

  const clashingTimbersSelectOptions = useMemo(
    () => withBlankOption(clashingTimbers),
    [clashingTimbers]
  );

  const skinCodesSelectOptions = useMemo(() => withBlankOption(skinCodes), [
    skinCodes,
  ]);

  const coreTypesSelectOptions = useMemo(() => withBlankOption(coreTypes), [
    coreTypes,
  ]);

  const meetingStilesSelectOptions = useMemo(
    () => withBlankOption(meetingStiles),
    [meetingStiles]
  );

  const doorTypeOptions = [
    {
      value: "SL",
      label: "Single",
    },
    {
      value: "PR",
      label: "Pair",
    },
    {
      value: "PU",
      label: "Uneven Pair",
    },
    {
      value: "W",
      label: "Window",
    },
  ];

  return (
    <div className="card card-top card-top-big">
      <div className="card-header">
        <h2 className="card-heading">Door Details</h2>
        <hr />
        <PricingOverview doorData={data} />
      </div>
      <div className="card-body py-3 pb-5">
        <div className="form-row">
          <div className="col-6 col-sm-6 col-md-4 col-lg-4">
            <div className="form-group">
              <label htmlFor="serial">Serial</label>
              <Field
                name="serial"
                placeholder="Serial"
                component={Input}
                disabled
              />
            </div>
          </div>

          <div className="col-6 col-sm-6 col-md-4 col-lg-4">
            <div className="form-group">
              <label htmlFor="reference">Reference</label>
              <Field
                name="reference"
                placeholder="Reference"
                component={Input}
              />
            </div>
          </div>

          <div className="col-6 col-sm-6 col-md-4 col-lg-4">
            <div className="form-group">
              <label htmlFor="door_type">Type</label>
              <Field
                name="door_type"
                placeholder="Type"
                component={DropdownInput}
                options={doorTypeOptions}
                as="select"
              >
                <option value=""></option>
              </Field>
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-6 col-sm-6 col-md-3 col-lg-3">
            <div className="form-group">
              <label htmlFor="height">Height</label>
              <Field name="height" placeholder="Height" component={Input} />
            </div>
          </div>
          <div className="col-6 col-sm-6 col-md-3 col-lg-3">
            <div className="form-group">
              <label htmlFor="width">Width</label>
              <Field
                name="width"
                placeholder="Width"
                afterChange={nextFieldOnSlash}
                component={Input}
              />
            </div>
          </div>
          <div className="col-6 col-sm-6 col-md-3 col-lg-3">
            <div className="form-group">
              <label htmlFor="width_pair">Pair Width</label>
              <Field
                name="width_pair"
                placeholder="Pair Width"
                innerRef={pairWidth}
                component={Input}
              />
            </div>
          </div>
          <div className="col-6 col-sm-6 col-md-3 col-lg-3">
            <div className="form-group">
              <label htmlFor="thickness">Thickness</label>
              <Field
                name="thickness"
                placeholder="Thickness"
                component={Input}
              />
            </div>
          </div>
        </div>

        {}

        <div className="form-row">
          <div className="col-6 col-sm-6 col-md-4 col-lg-4">
            <div className="form-group">
              <label htmlFor="core_type_id">Core Type</label>
              <Field
                name="core_type_id"
                placeholder="Type"
                component={DropdownInput}
                options={coreTypesSelectOptions}
                as="select"
              ></Field>
            </div>
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-4">
            <div className="form-group">
              <label htmlFor="skin_code_id">Skin Type</label>
              <Field
                name="skin_code_id"
                placeholder="Skin"
                component={DropdownInput}
                options={skinCodesSelectOptions}
                as="select"
              >
                <option value=""></option>
              </Field>
            </div>
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-4">
            <div className="form-group">
              <label htmlFor="clashing_timber_id">Clashing Type</label>
              <Field
                name="clashing_timber_id"
                placeholder="Clashing"
                component={DropdownInput}
                options={clashingTimbersSelectOptions}
                as="select"
              >
                <option value=""></option>
              </Field>
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-6 col-sm-6 col-md-4 col-lg-4">
            <label htmlFor="reference">Number of Clashings</label>
            <Field
              name="number_of_clashings"
              placeholder="Number of Clashings"
              component={Input}
            />
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-4">
            <label htmlFor="reference">Clashing 1 Thickness</label>
            <Field
              name="clashing_1_thickness"
              placeholder="Clashing 1 Thickness"
              component={Input}
            />
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-4">
            <label htmlFor="">Clashing 2 Thickness</label>
            <Field
              name="clashing_2_thickness"
              placeholder="Clashing 2 Thickness"
              component={Input}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col-6 col-sm-6 col-md-4 col-lg-4">
            <div className="form-group">
              <label htmlFor="meeting_stile_id">Meeting Stile</label>
              <Field
                name="meeting_stile_id"
                placeholder="Clashing"
                component={DropdownInput}
                options={meetingStilesSelectOptions}
                as="select"
              ></Field>
            </div>
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-4">
            <label htmlFor="reference">Contribution Factor</label>
            <Field
              name="contribution_factor"
              placeholder="Contribution Factor"
              component={Input}
            />
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-4">
            <label htmlFor="reference">Discount Factor</label>
            <Field
              name="discount_factor"
              placeholder="Discount Factor"
              component={Input}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="form-group">
              <label htmlFor="door_details">Door Details</label>
              <Field
                name="door_details"
                placeholder="Details"
                component={EditableDropdownInput}
                options={doorDetails}
                as="select"
              ></Field>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoorDetails;
