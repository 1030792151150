import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import DoorDropdownMenu from "components/DoorsList/DoorDropdownMenu";
import DuplicateDoor from "components/DoorsList/DuplicateDoor";

function DoorUtilities({ door }) {
  const [selectedUtility, setSelectedUtility] = useState("dropdown");
  const [activeUtility, setUtility] = useState(null);

  const resourceId = door.id;
  const quoteId = door.quote_id;
  const isChild = door.parent_id ? true : false;

  useEffect(() => {
    switch (selectedUtility) {
      case "dropdown":
        setUtility(
          <DoorDropdownMenu
            resourceId={resourceId}
            setSelectedUtility={setSelectedUtility}
            isChild={isChild}
          />
        );
        break;
      case "duplicateDoor":
        setUtility(
          <DuplicateDoor
            resourceId={resourceId}
            quoteId={quoteId}
            setSelectedUtility={setSelectedUtility}
          />
        );
        break;
      default:
        setUtility(
          <DoorDropdownMenu
            resourceId={resourceId}
            setSelectedUtility={setSelectedUtility}
          />
        );
        break;
    }
  }, [selectedUtility]);

  return <>{activeUtility}</>;
}

DoorUtilities.propTypes = {};

export default DoorUtilities;
