import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "contexts/auth";
import { useSidebar } from "contexts/sidebar";

function Header({ pageTitle }) {
  const { toggleActive } = useSidebar();
  const { user } = useAuth();

  return (
    <nav className="navbar navbar-light">
      <div className="container-fluid">
        <button
          type="button"
          id="sidebarCollapse"
          className="btn sidebar__toggler"
          onClick={toggleActive}
        >
          <FontAwesomeIcon icon={["fa", "bars"]} className="icon" />
        </button>

        <h1 className="navbar-heading d-none d-sm-block">{pageTitle}</h1>
        <span className="d-xs-block d-sm-none"></span>

        <div>
          <ul className="navigation ml-auto">
            <li className="nav-item d-none d-lg-block">
              <div className="dropdown">
                <a
                  href="#"
                  className="dropdown-btn dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  {user && user.name}
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

Header.propTypes = {
  pageTitle: PropTypes.string.isRequired,
};

export default Header;
