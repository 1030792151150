import React from "react";
import { getDoors } from "utils/horizon-api-client";
import { useIndexData } from "hooks/useIndexData";

import DoorUtilities from "components/DoorsList/DoorUtilities";

export function useDoors({ quoteId }) {
  const dollarsToCents = (v) => v * 100;

  const searchOptions = {
    serial: {
      name: "id",
      aliases: ["serial"],
      predicate: "eq",
      displayName: "Serial",
      defaultSearchTerm: "serial:",
    },
    reference: {
      name: "reference",
      aliases: ["reference", "ref"],
      predicate: "cont",
      displayName: "Reference",
      defaultSearchTerm: "reference:",
    },
    height: {
      name: "height",
      aliases: ["height"],
      predicate: "eq",
      displayName: "Height (equals)",
      defaultSearchTerm: "height:",
    },
    height_gt: {
      name: "height",
      aliases: ["height"],
      predicate: "gt",
      displayName: "Height (greater than)",
      defaultSearchTerm: "height>",
    },
    height_lt: {
      name: "height",
      aliases: ["height"],
      predicate: "lt",
      displayName: "Height (less than)",
      defaultSearchTerm: "height<",
    },
    width: {
      name: "width",
      aliases: ["width"],
      predicate: "eq",
      displayName: "Width (equals)",
      defaultSearchTerm: "width:",
    },
    width_gt: {
      name: "width",
      aliases: ["width"],
      predicate: "gt",
      displayName: "Width (greater than)",
      defaultSearchTerm: "width>",
    },
    width_lt: {
      name: "width",
      aliases: ["width"],
      predicate: "lt",
      displayName: "Width (less than)",
      defaultSearchTerm: "width<",
    },
    sell_price_cents: {
      name: "sell_price_cents",
      aliases: ["sell_price_cents", "price"],
      predicate: "gt",
      displayName: "Unit Price (equals)",
      defaultSearchTerm: "price:",
      transformFunc: dollarsToCents,
    },
    sell_price_cents_gt: {
      name: "sell_price_cents",
      aliases: ["sell_price_cents", "price"],
      predicate: "gt",
      displayName: "Unit Price (greater than)",
      defaultSearchTerm: "price>",
      transformFunc: dollarsToCents,
    },
    sell_price_cents_lt: {
      name: "sell_price_cents",
      aliases: ["sell_price_cents", "price"],
      predicate: "lt",
      displayName: "Unit Price (less than)",
      defaultSearchTerm: "price<",
      transformFunc: dollarsToCents,
    },
  };

  const {
    refetch,
    recordData,
    totalCount,
    searchFieldValue,
    setSearchTerm,
    status,
  } = useIndexData({
    requestData: getDoors,
    requestParams: { quoteId },
    searchOptions: searchOptions,
    mapRecordToTable: mapDoorToTable,
  });

  function mapDoorToTable(door) {
    return {
      id: door.id,
      subRows: door.children.map((child) => mapDoorToTable(child)),
      position: door.position,
      height: door.height,
      width: door.width,
      reference: door.reference,
      parent_id: door.parent_id,
      serial: door.serial,
      sell_price: door.sell_price || 0,
      description: "Standard Door",
      dropdown: <DoorUtilities door={door} />,
    };
  }

  return {
    doors: recordData,
    totalDoors: totalCount,
    searchFieldValue: searchFieldValue,
    setSearchTerm: setSearchTerm,
    searchOptions: searchOptions,
    status: status,
    refetch,
  };
}
