import React from "react";
import PropTypes from "prop-types";
import { Map, TileLayer, Marker } from "react-leaflet";

function MapDisplay({ coords, shouldShowMarker }) {
  const zoom = shouldShowMarker ? 15 : 5;
  const position = [coords.lat, coords.lng];
  const marker = shouldShowMarker ? <Marker position={position} /> : null;

  if (typeof coords.lat === "undefined" || typeof coords.lng === "undefined")
    return null;

  return (
    <>
      <Map center={position} zoom={zoom}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {marker}
      </Map>
    </>
  );
}

MapDisplay.propTypes = {
  shouldShowMarker: PropTypes.bool,
  coords: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

export default MapDisplay;
