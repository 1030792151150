import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import TagsInput from "react-tagsinput";

function Search({ setSearchTerm, searchFieldValue, searchHelperOptions = [] }) {
  const [helperOpen, setHelperOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const tagsInput = useRef();

  function toggleHelperMenu() {
    setHelperOpen(!helperOpen);
  }

  function handleChange(terms) {
    setHelperOpen(false);
    setSearchTerm(terms);
  }

  function handleChangeInput(e) {
    setHelperOpen(false);
    setInputValue(e);
  }

  function addInputHelp(e) {
    setHelperOpen(false);
    setInputValue(e.target.getAttribute("searchValue"));
    tagsInput.current.focus();
  }

  return (
    <div className="input-group">
      <TagsInput
        className="search-input"
        value={searchFieldValue}
        onChange={handleChange}
        inputProps={{ placeholder: "Add Filter", ref: tagsInput }}
        inputValue={inputValue}
        onChangeInput={handleChangeInput}
      />
      <span
        onClick={toggleHelperMenu}
        className="material-icons-outlined search-icon"
      >
        search
      </span>

      <div
        className={
          helperOpen
            ? "show dropdown-menu search-menu"
            : "dropdown-menu search-menu"
        }
        id="dropmenu"
      >
        {searchHelperOptions.map((helper) => {
          return (
            <a
              className="dropdown-item"
              key={helper.defaultSearchTerm}
              onClick={addInputHelp}
              searchvalue={helper.defaultSearchTerm}
            >
              {helper.displayName}
            </a>
          );
        })}
      </div>
    </div>
  );
}

Search.propTypes = {
  setSearchTerm: PropTypes.func.isRequired,
  searchFieldValue: PropTypes.array.isRequired,
  searchHelperOptions: PropTypes.object,
};

export default Search;
