import axios from "axios";
import download from "downloadjs";
import { getToken, setToken } from "./auth-client";

const authHeaders = () => {
  return getToken();
};

function setTokenFromHeaders({ headers }) {
  setToken({
    client: headers.client,
    uid: headers.uid,
    accessToken: headers["access-token"],
    expiry: headers.expiry,
  });
}

async function get({ url, params, options = {} }) {
  const response = await axios.get(
    `${process.env.REACT_APP_HORIZON_API_HOST}${url}`,
    Object.assign(
      {},
      {
        headers: authHeaders(),
        params: params,
      },
      options
    )
  );
  setTokenFromHeaders({ headers: response.headers });
  return response;
}

async function post({ data, url }) {
  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_HORIZON_API_HOST}${url}`,
      headers: authHeaders(),
      data: data,
    });
    setTokenFromHeaders({ headers: response.headers });
    return response;
  } catch (err) {
    setTokenFromHeaders({ headers: err.response.headers });
    return err.response;
  }
}

async function patch({ data, url }) {
  try {
    const response = await axios({
      method: "patch",
      url: `${process.env.REACT_APP_HORIZON_API_HOST}${url}`,
      headers: authHeaders(),
      data: data,
    });
    setTokenFromHeaders({ headers: response.headers });
    return response;
  } catch (err) {
    setTokenFromHeaders({ headers: err.response.headers });
    return err.response;
  }
}

export const validateTokenWithApi = () => {
  return axios.get(
    process.env.REACT_APP_HORIZON_API_HOST + "/auth/validate_token",
    {
      headers: authHeaders(),
    }
  );
};

export const getCustomers = async ({ page = 0, perPage = 25, filter = {} }) => {
  let response;
  if (Object.keys(filter).length) {
    response = await post({
      url: "/api/v1/customers/search",
      data: {
        q: filter,
        page: page,
        perPage: perPage,
      },
    });
  } else {
    response = await get({
      url: "/api/v1/customers",
      params: {
        page: page,
        perPage: perPage,
      },
    });
  }

  return response.data;
};

export const getEndCustomers = async ({
  page = 0,
  perPage = 25,
  filter = {},
}) => {
  let response;
  if (Object.keys(filter).length) {
    response = await post({
      url: "/api/v1/end_customers/search",
      data: {
        q: filter,
        page: page,
        perPage: perPage,
      },
    });
  } else {
    response = await get({
      url: "/api/v1/end_customers",
      params: {
        page: page,
        perPage: perPage,
      },
    });
  }

  return response.data;
};

export const getJob = async ({ id }) => {
  const response = await get({
    url: `/api/v1/jobs/${id}`,
  });

  return response;
};

export const getJobs = async ({ page = 0, perPage = 25, filter = {} }) => {
  let response;
  if (Object.keys(filter).length) {
    response = await post({
      url: "/api/v1/jobs/search",
      data: {
        q: filter,
        page: page,
        perPage: perPage,
      },
    });
  } else {
    response = await get({
      url: "/api/v1/jobs",
      params: {
        page: page,
        perPage: perPage,
      },
    });
  }

  return response.data;
};

export const cloneQuote = async ({ id }) => {
  const response = await post({
    url: `/api/v1/quotes/${id}/clones`,
    data: {},
  });
  return response;
};

export const updateQuote = async ({ id, values }) => {
  const response = await patch({
    url: `/api/v1/quotes/${id}`,
    data: values,
  });
  return response;
};

export const getQuote = async ({ id }) => {
  const response = await get({
    url: `/api/v1/quotes/${id}`,
  });

  return response;
};

export const getQuotes = async ({ page = 0, perPage = 25, filter = {} }) => {
  let response;
  if (Object.keys(filter).length) {
    response = await post({
      url: "/api/v1/quotes/search",
      data: {
        q: filter,
        page: page,
        perPage: perPage,
      },
    });
  } else {
    response = await get({
      url: "/api/v1/quotes",
      params: {
        page: page,
        perPage: perPage,
      },
    });
  }

  return response.data;
};

export const getJobQuotes = async ({ id }) => {
  const response = await get({
    url: `/api/v1/jobs/${id}/quotes`,
  });

  return response.data;
};

export const createQuote = async ({ values }) => {
  const response = await post({
    url: "/api/v1/quotes",
    data: values,
  });
  return response;
};

export const createCustomer = async ({ values }) => {
  const response = await post({
    url: "/api/v1/customers",
    data: values,
  });
  return response;
};

export const updateCustomer = async ({ id, values }) => {
  const response = await patch({
    url: `/api/v1/customers/${id}`,
    data: values,
  });
  return response;
};

export const getCustomer = async ({ id }) => {
  const response = await get({
    url: `/api/v1/customers/${id}`,
  });

  return response;
};

export const createEndCustomer = async ({ values }) => {
  const response = await post({
    url: "/api/v1/end_customers",
    data: values,
  });
  return response;
};

export const updateEndCustomer = async ({ id, values }) => {
  const response = await patch({
    url: `/api/v1/end_customers/${id}`,
    data: values,
  });
  return response;
};

export const getEndCustomer = async ({ id }) => {
  const response = await get({
    url: `/api/v1/end_customers/${id}`,
  });

  return response;
};

export const createJob = async ({ values }) => {
  const response = await post({
    url: "/api/v1/jobs",
    data: values,
  });
  return response;
};

export const updateJob = async ({ id, values }) => {
  const response = await patch({
    url: `/api/v1/jobs/${id}`,
    data: values,
  });
  return response;
};

export const getUsers = async () => {
  const response = await get({
    url: "/api/v1/users",
  });

  return response.data;
};

export const getUser = async ({ id }) => {
  const response = await get({
    url: `/api/v1/users/${id}`,
  });

  return response;
};

export const updateUser = async ({ id, values }) => {
  const response = await patch({
    url: `/api/v1/users/${id}`,
    data: values,
  });
  return response;
};

export const getDoor = async ({ id }) => {
  const response = await get({
    url: `/api/v1/doors/${id}`,
  });

  return response;
};

export const getDoorConfigOptions = async () => {
  const response = await get({
    url: `/api/v1/doors/configuration_options`,
  });

  return response;
};

export const getDoors = async ({ quoteId, filter = {} }) => {
  let response;
  if (Object.keys(filter).length) {
    response = await post({
      url: `/api/v1/quotes/${quoteId}/doors/search`,
      data: {
        q: filter,
      },
    });
  } else {
    response = await get({
      url: `/api/v1/quotes/${quoteId}/doors`,
    });
  }

  return response.data;
};

export const createDoor = async ({ quoteId, values }) => {
  const response = await post({
    url: `/api/v1/quotes/${quoteId}/doors`,
    data: values,
  });
  return response;
};

export const updateDoor = async ({ id, values }) => {
  const response = await patch({
    url: `/api/v1/doors/${id}`,
    data: values,
  });
  return response;
};

export const cloneDoor = async ({ id, qty }) => {
  const response = await post({
    url: `/api/v1/doors/${id}/clones`,
    data: {
      qty: qty,
    },
  });
  return response;
};

export const updateDoorPosition = async ({ id, newPosition }) => {
  const response = await patch({
    url: `/api/v1/doors/${id}`,
    data: { position: newPosition },
  });
  return response;
};

export const exportQuoteReport = async ({ id, reportId, fileName }) => {
  const response = await get({
    url: `/api/v1/quotes/${id}/reports/${reportId}`,
    options: { responseType: "arraybuffer" },
  });
  const content = response.headers["content-type"];
  return response;
};
