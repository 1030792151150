export function serializeQuoteForm({ formData, values }) {
  formData.append("order_no", values.order_no);
  formData.append("customer_id", values.customer_id);
  formData.append("job_id", values.job_id);
  formData.append("tagged", values.tagged);
  formData.append("status", values.status);
  formData.append("quote_date", values.quote_date);
  formData.append("public_notes", values.public_notes);
  formData.append("internal_notes", values.internal_notes);

  if (typeof values.freight_charge !== "undefined") {
    formData.append(
      "freight_charge[description]",
      values.freight_charge.description
    );
    formData.append("freight_charge[cost]", values.freight_charge.cost);
  }

  if (values.files === "undefined") {
    values.files = [];
  }

  values.files.forEach((file) => {
    const fileIsPersistedToServer = file.id;
    if (!fileIsPersistedToServer) {
      formData.append(`files[]`, file);
    }
  });

  return formData;
}
