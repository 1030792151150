import React from "react";
import PropTypes from "prop-types";
import { FieldArray, useFormikContext } from "formik";
import { find } from "lodash";

import GrilleOpening from "components/DoorForm/GrilleOpening";
import { useDoorConfigOptions } from "hooks/useDoorConfigOptions";

function GrilleOpeningsList({ onDelete }) {
  const { values } = useFormikContext();
  const { grilleOpenings } = useDoorConfigOptions();

  const panelCount = values.grille_opening_ids
    ? values.grille_opening_ids.length
    : 0;

  function getPanelData(id) {
    const data = find(grilleOpenings, (panel) => panel.value === id);
    return data || {};
  }

  function addPanel(e, arrayHelpers) {
    e.preventDefault();
    arrayHelpers.insert(
      values.grille_opening_ids ? values.grille_opening_ids.length : 0,
      ""
    );
  }

  function removePanel(e, index, arrayHelpers) {
    e.preventDefault();
    if (typeof onDelete === "function") {
      onDelete(values.grille_opening_ids[index]);
    }
    arrayHelpers.remove(index);
  }

  function duplicatePanel(e, index, arrayHelpers) {
    e.preventDefault();
    const newPanel = values.grille_opening_ids[index];
    arrayHelpers.insert(values.grille_opening_ids.length, newPanel);
  }

  return (
    <FieldArray
      name="grille_opening_ids"
      render={(arrayHelpers) => (
        <div className="card mt-0">
          <div className="card-header d-flex align-items-center">
            <h2 className="card-heading mb-0">Grilles & Options ({panelCount})</h2>
            <a
              href="#"
              className="btn btn-rounded btn-red btn-shadow ml-auto"
              onClick={(e) => {
                addPanel(e, arrayHelpers);
              }}
            >
              <span className="material-icons-outlined">add</span>
            </a>
          </div>

          <div className="card-body">
            {values.grille_opening_ids && values.grille_opening_ids.length > 0
              ? values.grille_opening_ids.map((panel, index) => (
                  <GrilleOpening
                    index={index}
                    key={index}
                    onRemove={(e) => {
                      removePanel(e, index, arrayHelpers);
                    }}
                    onDuplicate={(e) => {
                      duplicatePanel(e, index, arrayHelpers);
                    }}
                    objectData={getPanelData(panel)}
                  />
                ))
              : ""}
          </div>
        </div>
      )}
    />
  );
}

GrilleOpeningsList.propTypes = {
  onDelete: PropTypes.func,
};

export default GrilleOpeningsList;
