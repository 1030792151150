import React from "react";
import Search from "components/Search";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { camelCase, kebabCase } from "lodash";

function DoorsTableHeader({
  searchFieldValue,
  setSearchTerm,
  searchHelperOptions,
  resourceName,
}) {
  return (
    <>
      <div
        className="content-header"
        id={`${camelCase(resourceName)}-table-header`}
      >
        <div className="content-search">
          <div className="input-group d-flex align-items-center">
            <Search
              setSearchTerm={setSearchTerm}
              searchFieldValue={searchFieldValue}
              searchHelperOptions={searchHelperOptions}
            />
          </div>
        </div>

        <Link
          className="btn btn-filled btn-bordered btn-small"
          to={`${kebabCase(resourceName)}s/new`}
        >
          <span className="material-icons-outlined">add</span> New{" "}
          {resourceName}
        </Link>
      </div>
    </>
  );
}

DoorsTableHeader.propTypes = {
  searchFieldValue: PropTypes.array.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  resourceName: PropTypes.string.isRequired,
  searchHelperOptions: PropTypes.object.isRequired,
};

export default DoorsTableHeader;
