import React, { useEffect } from "react";
import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";

import MapWithMarker from "components/JobForm/MapWithMarker";
import DropdownInput from "components/Formik/DropdownInput";
import Input from "components/Formik/Input";
import { useEndCustomer } from "hooks/useEndCustomer";
import { useDynamicAvailableCities } from "hooks/useDynamicAvailableCities";
import { useAddressSuggestions } from "hooks/useAddressSuggestions";

function JobAddressForm({ canCreate }) {
  const { values, setFieldValue } = useFormikContext();
  const { data: endCustomerData } = useEndCustomer({
    id: values.end_customer_id,
  });
  const { handleFieldChange, addressResultsList } = useAddressSuggestions();
  const { availableCities } = useDynamicAvailableCities();

  useEffect(() => {
    if (
      typeof endCustomerData !== "undefined" &&
      typeof endCustomerData.address !== "undefined" &&
      checkAddressDataEmpty()
    ) {
      setFieldValue("address.line_1", endCustomerData.address.line_1);
      setFieldValue("address.line_2", endCustomerData.address.line_2);
      if (endCustomerData.address.city.length) {
        setFieldValue("address.city", endCustomerData.address.city);
      }
      setFieldValue("address.post_code", endCustomerData.address.post_code);
    }
  }, [values.end_customer_id, endCustomerData]);

  function checkAddressDataEmpty() {
    if (typeof values.address === "undefined") return false;
    if (values.address.line_1.length) return false;
    if (values.address.line_2.length) return false;
    if (values.address.post_code.length) return false;
    return true;
  }

  return (
    <>
      <p className="div-label">Site Address</p>
      <div className="label-div">
        <div className="form-row">
          <div className="col-md-5">
            <div className="form-group">
              <label htmlFor="line-1">Line 1</label>
              <Field
                name="address.line_1"
                placeholder="Line 1"
                component={Input}
                disabled={!canCreate}
                afterChange={handleFieldChange}
              />
            </div>

            {addressResultsList}

            <div className="form-group">
              <label htmlFor="line-2">Line 2</label>
              <Field
                name="address.line_2"
                placeholder="Line 2"
                component={Input}
                disabled={!canCreate}
              />
            </div>
            <div className="form-group">
              <label htmlFor="city">City</label>
              <Field
                name="address.city"
                placeholder="City"
                options={availableCities}
                as="select"
                component={DropdownInput}
                disabled={!canCreate}
              ></Field>
            </div>
            <div className="form-group">
              <label htmlFor="pcode">Post Code</label>
              <Field
                name="address.post_code"
                placeholder="Post Code"
                component={Input}
                disabled={!canCreate}
              />
            </div>
          </div>
          <div className="col-md-7">
            <MapWithMarker />
          </div>
        </div>
      </div>
    </>
  );
}

JobAddressForm.propTypes = {
  canCreate: PropTypes.bool.isRequired,
};

export default JobAddressForm;
