import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { flatten } from "lodash";
import { useSearch } from "hooks/useSearch";

// Non-paginated version of useInfiniteIndexData

export function useIndexData({
  nestedResourceId = null,
  requestData,
  requestParams,
  searchOptions,
  mapRecordToTable,
}) {
  const {
    searchFilter,
    searchKey,
    searchFieldValue,
    setSearchTerm,
  } = useSearch(searchOptions);

  const { status, data = [], refetch } = useQuery(
    buildFetchCacheKey(),
    fetchData
  );
  const recordData = useMemo(allRecords, [data]);
  const totalCount = useMemo(() => {
    if (typeof data !== "undefined") {
      return data.totalCount;
    }
  }, [data]);

  function buildFetchCacheKey() {
    if (nestedResourceId) {
      return `${requestData.name}-${nestedResourceId}-${searchKey}`;
    } else {
      return `${requestData.name}-${searchKey}`;
    }
  }

  async function fetchData(key) {
    const records = await requestData(
      Object.assign({}, requestParams, {
        filter: searchFilter,
      })
    );

    return {
      data: records.data,
      totalCount: records.total,
    };
  }

  function allRecords() {
    if (typeof data.data === "undefined") return [];
    const arr = [];
    data.data.forEach((record) => {
      arr.push(mapRecordToTable(record));
    });
    return flatten(arr);
  }

  return {
    refetch,
    recordData,
    totalCount,
    searchFieldValue,
    setSearchTerm,
    searchOptions,
    status,
  };
}
