import React, { useMemo } from "react";
import { useAuth } from "contexts/auth";

export function useEndCustomerPolicy() {
  const { userRole } = useAuth();

  const canCreate = useMemo(() => {
    return userRole === "admin" || userRole === "management";
  }, [userRole]);

  return {
    canCreate,
  };
}
