import React, { useState, useEffect } from "react";
import Select from "react-select";

import Input from "components/Formik/Input";

import { ErrorMessage } from "formik";
import { isEqual } from "lodash";

import "assets/stylesheets/editableDropdownInput.css";

const EditableDropdownInput = ({ field, form, options, ...props }) => {
  const [isSelected, setIsSelected] = useState(false);

  const meta = form.getFieldMeta(field.name);
  const hasError = typeof meta.error !== "undefined";
  const hasBeenTouched = meta.touched;
  const shouldDisplayError = hasError && hasBeenTouched;

  useEffect(() => {
    if (field.value === undefined || field.value === "") {
      setIsSelected(false);
    } else {
      setIsSelected(true);
    }
  }, [field.value]);

  function onSelect(option) {
    form.setFieldValue(field.name, option.value);
  }

  function onReset() {
    form.setFieldValue(field.name, "");
  }

  return (
    <>
      {isSelected && (
        <>
          <div id="editable-input">
            <Input field={field} form={form} />
            <span
              onClick={onReset}
              id="clear-button"
              className="material-icons-outlined"
            >
              menu
            </span>
          </div>
        </>
      )}

      {!isSelected && (
        <Select
          options={options}
          name={field.name}
          value={
            options
              ? options.find((option) => isEqual(option.value, field.value))
              : ""
          }
          onChange={onSelect}
          onBlur={field.onBlur}
          isDisabled={props.disabled}
          className={
            shouldDisplayError
              ? "dropdownInput-wrapper invalid-input"
              : "dropdownInput-wrapper"
          }
        />
      )}
      {shouldDisplayError && (
        <span className="input-error">
          <ErrorMessage name={field.name} />
        </span>
      )}
    </>
  );
};

export default EditableDropdownInput;
