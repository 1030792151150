import React from "react";
import { Field } from "formik";
import PropTypes from "prop-types";
import { useDoorConfigOptions } from "hooks/useDoorConfigOptions";

import DropdownInput from "components/Formik/DropdownInput";

function GrilleOpening({ index, onRemove, onDuplicate, objectData }) {
  const { grilleOpenings: dropdownOptions } = useDoorConfigOptions();

  return (
    <div className="form-group-bordered">
      <div className="card-header card-header-mobile d-flex d-sm-none">
        <span className="label">Option {index + 1}</span>
        <div className="form-icons-group ml-auto">
          <a href="#">
            <span className="material-icons-outlined">delete</span>
          </a>
        </div>
      </div>
      <span className="label d-none d-sm-block">Option {index + 1}</span>
      <div className="form-row mt-5 mt-sm-0 align-items-center mt-3">
        <div className="col-md-10 col-lg-10">
          <div className="form-row mt-0">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label htmlFor={`grille_opening_ids[${index}].panel_type`}>
                  Code
                </label>
                <Field
                  name={`grille_opening_ids[${index}]`}
                  placeholder="Code"
                  component={DropdownInput}
                  options={dropdownOptions}
                  as="select"
                >
                  <option value=""></option>
                </Field>
              </div>
            </div>

            <div className="col-6 col-sm-6 col-md-3 col-lg-3">
              <div className="form-group">
                <label>Cost</label>
                <input
                  className={"form-control"}
                  disabled
                  value={`$${objectData.cost_cents / 100}`}
                />
              </div>
            </div>

            <div className="col-6 col-sm-6 col-md-3 col-lg-3">
              <div className="form-group">
                <label>Sell</label>
                <input
                  className={"form-control"}
                  disabled
                  value={`$${objectData.sell_cents / 100}`}
                />
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="form-group">
                <label>Description</label>
                <input
                  className={"form-control"}
                  disabled
                  value={objectData.description}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2 col-lg-2 d-sm-block d-none">
          <div className="form-icons-group">
            <a href="" onClick={onDuplicate}>
              <span className="material-icons">file_copy</span>
            </a>
            <a href="" onClick={onRemove}>
              <span className="material-icons-outlined">delete</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

GrilleOpening.propTypes = {
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
};

export default GrilleOpening;
