import React from "react";
import PropTypes from "prop-types";
import { FieldArray, useFormikContext } from "formik";
import { find } from "lodash";

import DoorExtra from "components/DoorForm/DoorExtra";
import { useDoorConfigOptions } from "hooks/useDoorConfigOptions";

function DoorExtrasList({ onDelete }) {
  const { setFieldValue, values } = useFormikContext();
  const { doorExtras } = useDoorConfigOptions();

  const extrasCount = values.door_door_extras
    ? values.door_door_extras.length
    : 0;

  function getObjData(id) {
    const data = find(doorExtras, (obj) => obj.value === id);
    return data || {};
  }

  function addPanel(e, arrayHelpers) {
    e.preventDefault();
    arrayHelpers.insert(
      values.door_door_extras ? values.door_door_extras.length : 0,
      {
        qty: 1,
      }
    );
  }

  function removeObj(e, index, arrayHelpers) {
    e.preventDefault();
    if (typeof onDelete === "function") {
      onDelete(values.door_door_extras[index]);
    }
    const extra = values.door_door_extras[index];

    if (extra._destroy) {
      delete extra._destroy;
      setFieldValue(`door_door_extras[${index}]`, extra);
    } else {
      setFieldValue(
        `door_door_extras[${index}]`,
        Object.assign(extra, { _destroy: true })
      );
    }
  }

  function duplicateObj(e, index, arrayHelpers) {
    e.preventDefault();
    const newPanel = Object.assign({}, values.door_door_extras[index]);
    newPanel.id = null;
    arrayHelpers.insert(values.door_door_extras.length, newPanel);
  }

  return (
    <FieldArray
      name="door_door_extras"
      render={(arrayHelpers) => (
        <div className="card mt-0">
          <div className="card-header d-flex align-items-center">
            <h2 className="card-heading mb-0">Extras ({extrasCount})</h2>
            <a
              href="#"
              className="btn btn-rounded btn-red btn-shadow ml-auto"
              onClick={(e) => {
                addPanel(e, arrayHelpers);
              }}
            >
              <span className="material-icons-outlined">add</span>
            </a>
          </div>

          <div className="card-body">
            {values.door_door_extras && values.door_door_extras.length > 0
              ? values.door_door_extras.map((obj, index) => (
                  <DoorExtra
                    index={index}
                    key={index}
                    doorDoorExtra={obj}
                    onRemove={(e) => {
                      removeObj(e, index, arrayHelpers);
                    }}
                    onDuplicate={(e) => {
                      duplicateObj(e, index, arrayHelpers);
                    }}
                    objectData={getObjData(obj.door_extra_id)}
                  />
                ))
              : ""}
          </div>
        </div>
      )}
    />
  );
}

DoorExtrasList.propTypes = {
  onDelete: PropTypes.func,
};

export default DoorExtrasList;
