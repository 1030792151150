import { find } from "lodash";

export function withBlankOption(selectOptionsArr) {
  const blankOption = find(
    selectOptionsArr,
    (opt) => opt.label === null || opt.value === null
  );
  if (typeof blankOption !== "undefined") {
    return selectOptionsArr;
  } else {
    return [{ value: null, label: " " }, ...selectOptionsArr];
  }
}
