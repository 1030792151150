import React from "react";
import { Route, Switch } from "react-router-dom";

import PrivateRoute from "utils/PrivateRoute";
import LoginCallback from "utils/LoginCallback";

import Home from "pages/Home";

import NewDoor from "pages/NewDoor";
import EditDoor from "pages/EditDoor";

import Users from "pages/Users";
import EditUser from "pages/EditUser";

import EndCustomers from "pages/EndCustomers";
import NewEndCustomer from "pages/NewEndCustomer";
import EditEndCustomer from "pages/EditEndCustomer";

import Customers from "pages/Customers";
import NewCustomer from "pages/NewCustomer";
import EditCustomer from "pages/EditCustomer";

import Jobs from "pages/Jobs";
import NewJob from "pages/NewJob";
import EditJob from "pages/EditJob";

import EditQuote from "pages/EditQuote";
import NewQuote from "pages/NewQuote";
import Quotes from "pages/Quotes";

const ROUTES = [
  {
    key: "LOGIN_CALLBACK",
    path: "/login/callback",
    isExact: true,
    isPrivate: false,
    component: LoginCallback,
  },
  {
    key: "APP",
    path: "/",
    isPrivate: true,
    isExact: false,
    component: RenderRoutes,
    routes: [
      {
        path: "/end-customers/new",
        key: "NEW_END_CUSTOMER",
        isExact: true,
        isPrivate: true,
        component: NewEndCustomer,
      },
      {
        path: "/end-customers/:id/edit",
        key: "EDIT_END_CUSTOMER",
        isExact: true,
        isPrivate: true,
        component: EditEndCustomer,
      },
      {
        path: "/end-customers",
        key: "ENDCUSTOMERS",
        isExact: true,
        isPrivate: true,
        component: EndCustomers,
      },
      {
        path: "/customers/new",
        key: "NEW_CUSTOMER",
        isExact: true,
        isPrivate: true,
        component: NewCustomer,
      },
      {
        path: "/customers/:id/edit",
        key: "EDIT_CUSTOMER",
        isExact: true,
        isPrivate: true,
        component: EditCustomer,
      },
      {
        path: "/customers",
        key: "CUSTOMERS",
        isExact: true,
        isPrivate: true,
        component: Customers,
      },
      {
        path: "/jobs/:id/edit",
        key: "EDIT_JOB",
        isExact: true,
        isPrivate: true,
        component: EditJob,
      },
      {
        path: "/jobs/new",
        key: "NEW_JOB",
        isExact: true,
        isPrivate: true,
        component: NewJob,
      },
      {
        path: "/jobs",
        key: "JOBS",
        isExact: true,
        isPrivate: true,
        component: Jobs,
      },
      {
        path: "/doors/:id/edit",
        key: "EDIT_DOOR",
        isExact: true,
        isPrivate: true,
        component: EditDoor,
      },
      {
        path: "/quotes/:id/doors/new",
        key: "NEW_DOOR",
        isExact: true,
        isPrivate: true,
        component: NewDoor,
      },
      {
        path: "/quotes/:id/edit",
        key: "EDIT_QUOTE",
        isExact: true,
        isPrivate: true,
        component: EditQuote,
      },
      {
        path: "/quotes/new",
        key: "NEW_QUOTE",
        isExact: true,
        isPrivate: true,
        component: NewQuote,
      },
      {
        path: "/quotes",
        key: "QUOTES",
        isExact: true,
        isPrivate: true,
        component: Quotes,
      },
      {
        path: "/users/:id/edit",
        key: "EDIT_USER",
        isExact: true,
        isPrivate: true,
        component: EditUser,
      },
      {
        path: "/users",
        key: "USERS",
        isExact: true,
        isPrivate: true,
        component: Users,
      },
      {
        path: "/",
        key: "HOME",
        isExact: true,
        isPrivate: true,
        component: Home,
      },
    ],
  },
];

function RouteWithSubRoutes(route) {
  if (route.isPrivate) {
    return (
      <PrivateRoute
        path={route.path}
        component={route.component}
        exact={route.isExact}
        routes={route.routes}
      />
    );
  } else {
    return (
      <Route
        path={route.path}
        exact={route.isExact}
        render={(props) => <route.component {...props} routes={route.routes} />}
      />
    );
  }
}

export function RenderRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, i) => {
        return <RouteWithSubRoutes key={route.key} {...route} />;
      })}
    </Switch>
  );
}

export default ROUTES;
