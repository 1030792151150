import React from "react";
import { useInfiniteIndexData } from "hooks/useInfiniteIndexData";

import QuoteUtilities from "components/QuotesList/QuoteUtilities";

import moment from "moment";

export function useQuotes({ requestData, nestedResourceId }) {
  const searchOptions = {
    quote_no: {
      name: "quote_no",
      aliases: ["id", "quote", "number", "no"],
      predicate: "cont_any",
      displayName: "Quote No.",
      defaultSearchTerm: "quote:",
    },
    company_name: {
      name: "customer_company_name",
      aliases: ["customer", "cust", "company_name", "customer_name"],
      predicate: "cont_any",
      displayName: "Customer Name",
      defaultSearchTerm: "customer:",
    },
    sales_person: {
      name: "user_name",
      aliases: ["sales", "sales person", "salesperson", "sales_person", "user"],
      predicate: "cont_any",
      displayName: "Sales Person",
      defaultSearchTerm: "sales person:",
    },
    status: {
      name: "status",
      aliases: ["st", "stat", "status"],
      predicate: "cont_any",
      displayName: "Status",
      defaultSearchTerm: "status:",
    },
    last_updated_gt: {
      name: "updated_at",
      aliases: ["updated", "last_updated"],
      predicate: "gteq",
      displayName: "Last Updated (after)",
      defaultSearchTerm: "updated>",
    },
    last_updated_lt: {
      name: "updated_at",
      aliases: ["updated", "last_updated"],
      predicate: "lteq",
      displayName: "Last Updated (before)",
      defaultSearchTerm: "updated<",
    },
    other: {
      name: "customer_company_name",
      aliases: [],
      predicate: "cont_any",
    },
  };

  const {
    fetchMore,
    canFetchMore,
    recordData,
    totalCount,
    searchFieldValue,
    setSearchTerm,
    status,
  } = useInfiniteIndexData({
    requestData,
    searchOptions,
    nestedResourceId,
    perPage: 25,
    mapRecordToTable: mapQuoteToTable,
  });

  function mapQuoteToTable(quote) {
    return {
      quote_no: quote.quote_no,
      description: quote.description,
      order_no: quote.order_no,
      customer_name: quote.customer.company_name,
      total_doors: quote.total_doors,
      total_cost: quote.total_cost_cents / 100,
      total_sale_price: quote.total_sale_price_cents / 100,
      sales_person: quote.user.name,
      last_updated: moment(quote.updated_at).fromNow(),
      status: <QuoteStatusButton status={quote.status} />,
      dropdown: <QuoteUtilities quote={quote} />,
    };
  }

  return {
    fetchMore: fetchMore,
    canFetchMore: canFetchMore,
    quotes: recordData,
    totalQuotes: totalCount,
    status: status,
    searchOptions: searchOptions,
    searchFieldValue: searchFieldValue,
    setSearchTerm: setSearchTerm,
  };
}

function QuoteStatusButton({ status }) {
  let buttonClass;

  switch (status) {
    case "draft":
      buttonClass = "btn-pending";
      break;
    case "sent":
      buttonClass = "btn-active";
      break;
  }

  return <a className={"table-column-btn " + buttonClass}>{status}</a>;
}
