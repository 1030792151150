import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import DropdownMenu from "components/DropdownMenu";

function DoorDropdownMenu({ resourceId, setSelectedUtility, isChild }) {
  return (
    <>
      <DropdownMenu resourceId={resourceId}>
        <Link to={`/doors/${resourceId}/edit`} className="dropdown-item">
          Edit Door
        </Link>
        {!isChild && (
          <Link
            className="dropdown-item"
            onClick={() => {
              setSelectedUtility("duplicateDoor");
            }}
          >
            Duplicate door
          </Link>
        )}
      </DropdownMenu>
    </>
  );
}

DoorDropdownMenu.propTypes = {
  resourceId: PropTypes.number.isRequired,
  setSelectedUtility: PropTypes.func.isRequired,
};

export default DoorDropdownMenu;
