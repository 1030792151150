import React, { useState } from "react";
import { toast } from "react-toastify";
import { useParams, Link } from "react-router-dom";

import DoorForm from "components/DoorForm/DoorForm";
import DoorFormHeaderNav from "components/DoorForm/DoorFormHeaderNav";
import FormHeader from "components/FormHeader";

import { updateDoor } from "utils/horizon-api-client";
import { useDoor } from "hooks/useDoor";

function EditDoor() {
  const { id } = useParams();
  const { data, status, refetch } = useDoor({ id: id });
  const isLoading = status === "loading";

  async function onSubmit(values, actions) {
    const response = await updateDoor({
      id: id,
      values: values,
    });

    actions.setErrors(response.data.errors);
    sendNotification(response);
  }

  function sendNotification(response) {
    switch (response.status) {
      case 200:
        toast.success("Door successfully saved!");
        refetch();
        break;
      case 403:
        toast.error(
          "You do not have permissions to update this door. Please contact an Admin"
        );
        break;
      case 422:
        if (response.data.errors.base) {
          toast.error(response.data.errors.base[0]);
        } else {
          toast.error("Error saving door");
        }
        break;
      case 500:
        toast.error("Internal server error.");
        break;
    }
  }

  return (
    <>
      <DoorForm
        onSubmit={onSubmit}
        initialValues={data}
        isLoading={isLoading}
        quoteId={data.quote_id}
        headerRightNav={
          <DoorFormHeaderNav isSaving={false} handleSave={onSubmit} />
        }
        header={
          <FormHeader
            title={
              <>
                <Link to="/doors">
                  <span className="material-icons-outlined">arrow_back</span>
                </Link>
                Edit Door
              </>
            }
          />
        }
      />
    </>
  );
}

export default EditDoor;
