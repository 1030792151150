import React, { useMemo } from "react";
import Header from "components/Header";
import TableHeader from "components/TableHeader";
import IndexTable from "components/IndexTable";
import IndexTableWrapper from "components/IndexTableWrapper";

import { useEndCustomers } from "hooks/useEndCustomers";

function EndCustomers() {
  const {
    fetchMore,
    canFetchMore,
    endCustomers,
    totalEndCustomers,
    searchOptions,
    status,
    setSearchTerm,
    searchFieldValue,
  } = useEndCustomers();

  const columns = useMemo(
    () => [
      {
        Header: "ID ",
        accessor: "id",
        className: "v-sm-visible",
      },
      {
        Header: "Company Name ",
        accessor: "company_name",
      },
      {
        Header: "Primary Email ",
        accessor: "primary_email",
      },
      {
        Header: "Account Email ",
        accessor: "account_email",
        className: "v-sm-visible",
      },
      {
        Header: "",
        accessor: "extras",
        disableSortBy: true,
      },
    ],
    []
  );

  const searchHelperOptions = useMemo(() => {
    const opts = [];
    Object.keys(searchOptions).forEach((optionName) => {
      const option = searchOptions[optionName];
      if (typeof option.displayName !== "undefined") {
        opts.push({
          displayName: option.displayName,
          defaultSearchTerm: option.defaultSearchTerm,
        });
      }
    });
    return opts;
  }, []);

  return (
    <>
      <Header pageTitle="End Customers" />
      <IndexTableWrapper
        onScrollToBottom={fetchMore}
        canFetchMore={
          typeof canFetchMore === "undefined" ? false : canFetchMore
        }
      >
        <TableHeader
          setSearchTerm={setSearchTerm}
          searchFieldValue={searchFieldValue}
          searchHelperOptions={searchHelperOptions}
          resourceName="End Customer"
        />
        <IndexTable columns={columns} data={endCustomers} status={status} />
        <span className="table-footer-label">
          Showing {endCustomers.length} of {totalEndCustomers} end customers
        </span>
      </IndexTableWrapper>
    </>
  );
}

export default EndCustomers;
