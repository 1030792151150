import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { cloneQuote } from "utils/horizon-api-client";
import { useQuotes } from "hooks/useQuotes";

function DuplicateQuote({ resourceId }) {
  const [submitting, setSubmitting] = useState(false);

  async function handleConfirm(e) {
    e.preventDefault();
    if (submitting) {
      return false;
    }

    const confirmed = window.confirm(
      `This will duplicate the quote. Continue?`
    );

    if (confirmed) {
      setSubmitting(true);
      const response = await cloneQuote({ id: resourceId });
      setSubmitting(false);

      switch (response.status) {
        case 200:
          toast.success(`Successfully duplicated quote`);
          window.location.reload();
          break;
        case 403:
          toast.error("You do not have permissions to perform this action");
          break;
        case 422:
          toast.error("Error duplicating quote");
          break;
        case 500:
          toast.error("Internal server error.");
          break;
      }
    }
  }

  return (
    <>
      <Link to="#" className="dropdown-item" onClick={handleConfirm}>
        {submitting ? "Duplicating.." : "Duplicate Quote"}
      </Link>
    </>
  );
}

DuplicateQuote.propTypes = {
  resourceId: PropTypes.number.isRequired,
};

export default DuplicateQuote;
