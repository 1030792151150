import React, { useState, useMemo, useEffect } from "react";
import { Form, Formik } from "formik";
import { Persist } from "formik-persist";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import * as Yup from "yup";

import "assets/stylesheets/doorForm.css";

import QuoteFormHeader from "components/QuoteForm/QuoteFormHeader";

import LoadingOverlay from "components/LoadingOverlay";

import DoorDetails from "components/DoorForm/DoorDetails";
import PrehangSection from "components/DoorForm/PrehangSection";
import VisionPanelsList from "components/DoorForm/VisionPanelsList";
import GrilleOpeningsList from "components/DoorForm/GrilleOpeningsList";
import ResorcinolGluesList from "components/DoorForm/ResorcinolGluesList";
import DoorExtrasList from "components/DoorForm/DoorExtrasList";

import SkipDoorValidation from "utils/SkipDoorValidation";

function buildDoorSchema({ prehangActive, hingesNeeded, skipValidations }) {
  if (skipValidations) {
    return Yup.object();
  }

  return Yup.object().shape({
    order_no: Yup.string(),
    door_type: Yup.string().required("Required"),
    height: Yup.number().required("Required").min(300).max(3600),
    width: Yup.number().required("Required").min(200).max(1250),
    width_pair: Yup.number()
      .min(200)
      .max(1250)
      .nullable()
      .transform((v, ov) => (ov === 0 ? null : Number(ov))),
    thickness: Yup.number().required("Required").min(30).max(70),
    contribution_factor: Yup.number(),
    discount_factor: Yup.number(),
    core_type_id: Yup.number().nullable(),
    skin_code_id: Yup.number().nullable(),
    clashing_timber_id: Yup.number().nullable(),
    number_of_clashings: Yup.number().when("clashing_timber_id", {
      is: (val) => !!val,
      then: Yup.number().oneOf([1, 2]),
      otherwise: Yup.number()
        .oneOf([0, 1, 2, null])
        .nullable()
        .transform((v, ov) => (ov === 0 ? null : Number(ov))),
    }),
    clashing_1_thickness: Yup.number().when("number_of_clashings", {
      is: (val) => [1, 2].includes(val),
      then: Yup.number().min(1).max(25),
      otherwise: Yup.number(),
    }),
    clashing_2_thickness: Yup.number().when("number_of_clashings", {
      is: (val) => [1, 2].includes(val),
      then: Yup.number().min(1).max(25),
      otherwise: Yup.number(),
    }),
    meeting_stile_id: Yup.number().nullable(),
    door_jamb_handing_id: Yup.number().nullable(),
    reference: Yup.string().nullable(),
    vision_panel_ids: Yup.array(),
    ph_galv_steel_frame: Yup.boolean().when("prehangActive", {
      is: (v) => prehangActive,
      then: Yup.boolean(),
      otherwise: Yup.boolean().nullable(),
    }),
    discount_factor_phang: Yup.number(),
    number_of_hinges: Yup.number().when("door_jamb_handing_id", {
      is: (v) => prehangActive && hingesNeeded,
      then: Yup.number().min(2).max(6),
      otherwise: Yup.number().when("door_jamb_handing_id", {
        is: (v) => !prehangActive && hingesNeeded,
        then: Yup.number().notRequired(),
        otherwise: Yup.number().min(0).max(0),
      }),
    }),
    standard_jamb: Yup.boolean().when("prehangActive", {
      is: (v) => prehangActive,
      then: Yup.boolean(),
      otherwise: Yup.boolean().nullable(),
    }),
    jamb_thickness: Yup.number().when("prehangActive", {
      is: (v) => prehangActive,
      then: Yup.number().min(18).max(60),
    }),
    manual_machining_code: Yup.string(),
    number_of_sides: Yup.number().when("prehangActive", {
      is: (v) => prehangActive,
      then: Yup.number().min(1).max(4),
    }),
    wall_stud_width: Yup.number().when("prehangActive", {
      is: (v) => prehangActive,
      then: Yup.number().min(50).max(400),
    }),
    hinge_face_gib_lining_thickness: Yup.number(),
    non_hinge_face_gib_lining_thickness: Yup.number(),
  });
}

function DoorForm({
  quoteId,
  onSubmit,
  initialValues,
  isLoading,
  onVisionPanelDelete,
  shouldPersist,
  formName,
  headerRightNav,
}) {
  const history = useHistory();
  const [prehangActive, toggleShowPrehangActive] = useState(false);
  const [hingesNeeded, markHingesNeeded] = useState(true);
  const [skipValidations, markSkipValidations] = useState(false);

  useEffect(() => {
    toggleShowPrehangActive(initialValues.prehang_enabled);
  }, [initialValues.prehang_enabled]);

  const doorSchema = useMemo(
    () =>
      buildDoorSchema({
        prehangActive,
        hingesNeeded,
        skipValidations,
      }),
    [prehangActive, hingesNeeded, skipValidations]
  );

  function handleNavigation(e) {
    e.preventDefault();
    const selected = e.target.dataset.name;
    history.push(`/quotes/${quoteId}/edit?tab=${selected}`);
  }

  return (
    <Formik
      validationSchema={doorSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      <>
        <QuoteFormHeader
          onSubmit={onSubmit}
          onNavigation={handleNavigation}
          selectedNav={"quoteDoors"}
          isSaving={false}
          headerTitle={`Quote No: ${quoteId}`}
          rightNav={headerRightNav}
        />
        <LoadingOverlay isLoading={isLoading} />
        <Form>
          {shouldPersist && <Persist name={formName} />}
          <SkipDoorValidation markSkipValidations={markSkipValidations} />
          <DoorDetails />
          <VisionPanelsList onVisionPanelDelete={onVisionPanelDelete} />
          <GrilleOpeningsList onDelete={() => {}} />
          <ResorcinolGluesList onDelete={() => {}} />
          <DoorExtrasList onDelete={() => {}} />
          <PrehangSection
            prehangActive={prehangActive}
            toggleShowPrehangActive={toggleShowPrehangActive}
            markHingesNeeded={markHingesNeeded}
          />
        </Form>
      </>
    </Formik>
  );
}

DoorForm.propTypes = {
  quoteId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onVisionPanelDelete: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  shouldPersist: PropTypes.bool,
  formName: PropTypes.string,
  headerRightNav: PropTypes.node,
};

export default DoorForm;
