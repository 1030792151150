import React from "react";
import PropTypes from "prop-types";
import { FieldArray, useFormikContext } from "formik";
import { find } from "lodash";

import VisionPanel from "components/DoorForm/VisionPanel";
import { useDoorConfigOptions } from "hooks/useDoorConfigOptions";

function VisionPanelsList({ onVisionPanelDelete }) {
  const { values } = useFormikContext();
  const { visionPanels } = useDoorConfigOptions();

  const panelCount = values.vision_panel_ids
    ? values.vision_panel_ids.length
    : 0;

  function getPanelData(id) {
    const data = find(visionPanels, (panel) => panel.value === id);
    return data || {};
  }

  function addPanel(e, arrayHelpers) {
    e.preventDefault();
    arrayHelpers.insert(
      values.vision_panel_ids ? values.vision_panel_ids.length : 0,
      ""
    );
  }

  function removePanel(e, index, arrayHelpers) {
    e.preventDefault();
    if (typeof onVisionPanelDelete === "function") {
      onVisionPanelDelete(values.vision_panel_ids[index]);
    }
    arrayHelpers.remove(index);
  }

  function duplicatePanel(e, index, arrayHelpers) {
    e.preventDefault();
    const newPanel = values.vision_panel_ids[index];
    arrayHelpers.insert(values.vision_panel_ids.length, newPanel);
  }

  return (
    <FieldArray
      name="vision_panel_ids"
      render={(arrayHelpers) => (
        <div className="card mt-0">
          <div className="card-header d-flex align-items-center">
            <h2 className="card-heading mb-0">Vision Panels ({panelCount})</h2>
            <a
              href="#"
              className="btn btn-rounded btn-red btn-shadow ml-auto"
              onClick={(e) => {
                addPanel(e, arrayHelpers);
              }}
            >
              <span className="material-icons-outlined">add</span>
            </a>
          </div>

          <div className="card-body">
            {values.vision_panel_ids && values.vision_panel_ids.length > 0
              ? values.vision_panel_ids.map((panel, index) => (
                  <VisionPanel
                    index={index}
                    key={index}
                    onRemove={(e) => {
                      removePanel(e, index, arrayHelpers);
                    }}
                    onDuplicate={(e) => {
                      duplicatePanel(e, index, arrayHelpers);
                    }}
                    panelData={getPanelData(panel)}
                  />
                ))
              : ""}
          </div>
        </div>
      )}
    />
  );
}

VisionPanelsList.propTypes = {
  onVisionPanelDelete: PropTypes.func,
};

export default VisionPanelsList;
