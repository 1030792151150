import React, { useMemo } from "react";
import { find } from "lodash";
import { useQuery } from "react-query";

import { getEndCustomer } from "utils/horizon-api-client";

export function useEndCustomer({ id }) {
  const { data, status, refetch } = useQuery(
    ["end_customer", id],
    fetchEndCustomerById
  );
  const endCustomerData = useMemo(mapEndCustomerData, [data]);

  async function fetchEndCustomerById(key, id) {
    if (id === null) return {};

    const response = await getEndCustomer({ id: id });
    return response.data;
  }

  function mapEndCustomerData() {
    if (typeof data === "undefined") return {};

    return {
      company_name: data.company_name,
      notes: data.notes,
      contacts: [getPrimaryContact(), getAccountContact()],
      address: getAddress(),
    };
  }

  function getAddress() {
    if (typeof data === "undefined" || typeof data.address === "undefined")
      return {
        line_1: "",
        line_2: "",
        city: "",
        post_code: "",
      };

    return {
      id: data.address.id,
      line_1: data.address.line_1,
      line_2: data.address.line_2,
      city: data.address.city,
      post_code: data.address.post_code,
    };
  }

  function getPrimaryContact() {
    const contact = find(
      data.contacts,
      (contact) => contact.kind === "primary"
    );
    if (typeof contact === "undefined") return { kind: "primary" };

    return {
      id: contact.id,
      name: contact.name || "",
      last_name: contact.last_name || "",
      email: contact.email || "",
      phone: contact.phone || "",
      kind: contact.kind || "",
    };
  }

  function getAccountContact() {
    const contact = find(
      data.contacts,
      (contact) => contact.kind === "account"
    );
    if (typeof contact === "undefined") return { kind: "account" };

    return {
      id: contact.id,
      name: contact.name || "",
      email: contact.email || "",
      phone: contact.phone || "",
      kind: contact.kind || "",
    };
  }

  return {
    data: endCustomerData,
    status,
    refetch,
  };
}
