import React, { useMemo } from "react";
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Persist } from "formik-persist";

import Input from "components/Formik/Input";
import DropdownInput from "components/Formik/DropdownInput";
import DatePickerInput from "components/Formik/DatePickerInput";
import LoadingOverlay from "components/LoadingOverlay";
import JobAddressForm from "components/JobForm/JobAddressForm";
import { useJobPolicy } from "policies/useJobPolicy";
import { useEndCustomers } from "hooks/useEndCustomers";

const JobSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  end_customer_id: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

function JobForm({
  onSubmit,
  initialValues,
  isLoading,
  header,
  shouldPersist,
  formName,
}) {
  const { canCreate } = useJobPolicy();
  const { endCustomers, status } = useEndCustomers();
  const isLoadingEndCustomers = status === "loading";

  const endCustomerOptions = useMemo(() => {
    return endCustomers.map((eCust) => {
      return { value: eCust.id, label: eCust.company_name };
    });
  }, [endCustomers]);

  const statusOptions = [
    { value: "prospective", label: "Prospective" },
    { value: "active", label: "Active" },
    { value: "lost", label: "Lost" },
    { value: "completed", label: "Completed" },
  ];

  return (
    <Formik
      validationSchema={JobSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      <>
        {header}
        {shouldPersist && <Persist name={formName} />}
        <LoadingOverlay isLoading={isLoading} />

        <div className="card card-top">
          <div className="card-header">
            <h2 className="card-heading">Job Details</h2>
          </div>

          <div className="card-body">
            <Form>
              <div className="form-row">
                <div className="col-md-8 pl-0">
                  <div className="form-row mt-0">
                    <div className="col-md-8 width-80 pl-0">
                      <div className="form-row mt-0">
                        <div className="col-md-12 col-xs-8 px-sm-0 px-0">
                          <div className="form-group">
                            <label htmlFor="name">Job Name</label>
                            <Field
                              name="name"
                              placeholder="Job Name"
                              component={Input}
                              disabled={!canCreate}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 col-xs-8 px-sm-0 px-0 mt-sm-5 mt-0">
                        <div className="form-group">
                          <label htmlFor="end_customer_id">End Customer</label>

                          <Field
                            name="end_customer_id"
                            placeholder="End Customer"
                            component={DropdownInput}
                            options={endCustomerOptions}
                            as="select"
                            disabled={!canCreate || isLoadingEndCustomers}
                          >
                            <option value=""></option>
                            {endCustomers.map((customer) => {
                              return (
                                <option value={customer.id} key={customer.id}>
                                  {customer.company_name}
                                </option>
                              );
                            })}
                          </Field>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 width-80">
                      <div className="form-row mt-0">
                        <div className="col-xs-6 col-lg-12 px-sm-0 px-0">
                          <div className="form-group">
                            <label htmlFor="require-date">Required Date</label>
                            <Field
                              name="required_before"
                              placeholder="Required Before"
                              component={DatePickerInput}
                              disabled={!canCreate}
                            />
                          </div>
                        </div>
                        <div className="col-xs-6 col-lg-12 px-0 mt-sm-5 mt-0">
                          <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <Field
                              name="status"
                              options={statusOptions}
                              component={DropdownInput}
                              disabled={!canCreate}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pr-0">
                  <div className="form-row mt-0">
                    <div className="col-md-12 px-0">
                      <div className="form-group">
                        <label htmlFor="job-description">Job Description</label>
                        <Field
                          as="textarea"
                          cols="30"
                          name="description"
                          rows="5"
                          className="form-control"
                          disabled={!canCreate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <JobAddressForm canCreate={canCreate} />
            </Form>
          </div>
        </div>
      </>
    </Formik>
  );
}

JobForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  header: PropTypes.node.isRequired,
  shouldPersist: PropTypes.bool,
  formName: PropTypes.string,
};

export default JobForm;
