import React from "react";
import { Field } from "formik";
import PropTypes from "prop-types";
import { useDoorConfigOptions } from "hooks/useDoorConfigOptions";

import DropdownInput from "components/Formik/DropdownInput";
import Input from "components/Formik/Input";

function DoorExtra({
  index,
  onRemove,
  onDuplicate,
  objectData,
  doorDoorExtra,
}) {
  const { doorExtras: dropdownOptions } = useDoorConfigOptions();

  if(doorDoorExtra._destroy) return null;

  return (
    <div className="form-group-bordered">
      <div className="card-header card-header-mobile d-flex d-sm-none">
        <span className="label">
          Extra {index + 1}
        </span>
        <div className="form-icons-group ml-auto">
          <a href="#">
            <span className="material-icons-outlined">delete</span>
          </a>
        </div>
      </div>
      <span className="label d-none d-sm-block">Extra {index + 1}</span>

      <div
        className={`form-row mt-5 mt-sm-0 align-items-center mt-3 ${
          doorDoorExtra._destroy ? "for-destroy" : ""
        }`}
      >
        <div className="col-md-10 col-lg-10">
          <div className="form-row mt-0">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label htmlFor={`door_door_extras[${index}].door_extra_id`}>
                  Code
                </label>
                <Field
                  name={`door_door_extras[${index}].door_extra_id`}
                  placeholder="Code"
                  component={DropdownInput}
                  options={dropdownOptions}
                  as="select"
                >
                  <option value=""></option>
                </Field>
              </div>
            </div>

            <div className="col-2 col-sm-2 col-md-2 col-lg-2">
              <div className="form-group">
                <label htmlFor={`door_door_extras[${index}].qty`}>Qty</label>
                <Field
                  name={`door_door_extras[${index}].qty`}
                  placeholder={1}
                  component={Input}
                />
              </div>
            </div>

            <div className="col-2 col-sm-2 col-md-2 col-lg-2">
              <div className="form-group">
                <label>Cost</label>
                <input
                  className={"form-control"}
                  disabled
                  value={`$${(
                    (objectData.cost_cents / 100) *
                    doorDoorExtra.qty
                  ).toFixed(2)}`}
                />
              </div>
            </div>

            <div className="col-2 col-sm-2 col-md-2 col-lg-2">
              <div className="form-group">
                <label>Sell</label>
                <input
                  className={"form-control"}
                  disabled
                  value={`$${(
                    (objectData.sell_cents / 100) *
                    doorDoorExtra.qty
                  ).toFixed(2)}`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-1 col-lg-1 d-sm-block d-none">
          <div className="form-icons-group">
            <a href="" onClick={onDuplicate}>
              <span className="material-icons">file_copy</span>
            </a>
            <a href="" onClick={onRemove}>
              <span className="material-icons-outlined">delete</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

DoorExtra.propTypes = {
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
};

export default DoorExtra;
