import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory, Link, useLocation } from "react-router-dom";

import JobForm from "components/JobForm/JobForm";
import FormHeader from "components/FormHeader";
import { createJob } from "utils/horizon-api-client";
import { useJobPolicy } from "policies/useJobPolicy";

function NewJob() {
  const location = useLocation();
  const history = useHistory();
  const { canCreate } = useJobPolicy();

  const initialValues = {
    name: "",
    description: "",
    status: "prospective",
    required_before: "",
    end_customer_id: "",
    address: {
      line_1: "",
      line_2: "",
      city: "auckland",
      post_code: "",
      coords_y: "172.8344077",
      coords_x: "-41.5000831",
    },
  };

  async function onSubmit(values, actions) {
    const response = await createJob({
      values: values,
    });

    actions.setErrors(response.data.errors);
    sendNotification(response);

    if (response.status === 200) {
      // Needs to be called twice to clear form persistence.
      // Bug in formik-persist?
      actions.resetForm();
      actions.resetForm();

      redirectAfterCreate(response.data.id);
    }
  }

  function redirectAfterCreate(resourceId) {
    const searchParams = new URLSearchParams(location.search);
    const redirectTo = searchParams.get("postSaveRedirect");

    if (redirectTo !== null) {
      switch (redirectTo) {
        case "newQuote":
          history.push(`/quotes/new?jobId=${resourceId}`);
          break;
      }
    } else {
      redirectToJobEditPage(resourceId);
    }
  }

  function redirectToJobEditPage(jobId) {
    history.push(`/jobs/${jobId}/edit`);
  }

  function sendNotification(response) {
    switch (response.status) {
      case 200:
        toast.success("Job successfully saved!");
        break;
      case 403:
        toast.error(
          "You do not have permissions to create a job. Please contact an Admin"
        );
        break;
      case 422:
        toast.error("Error saving job.");
        break;
      case 500:
        toast.error("Internal server error.");
        break;
    }
  }

  return (
    <>
      <JobForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        isLoading={false}
        shouldPersist={true}
        formName="new-job-form"
        header={
          <FormHeader
            title={
              <>
                <Link to="/jobs">
                  <span className="material-icons-outlined">arrow_back</span>
                </Link>
                New Job
              </>
            }
            canCreate={canCreate}
          />
        }
      />
    </>
  );
}

export default NewJob;
