import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

function NavItem({ icon, text, to }) {
  const location = useLocation();
  const isActive = location.pathname.match(to);
  const focusedName = isActive ? "focused" : "";

  return (
    <li className={"sidebar__item " + focusedName}>
      <Link className="sidebar__link" to={to}>
        <span className="material-icons-outlined sidebar__link-icon">
          {icon}
        </span>

        <span className="sidebar__link-text">{text}</span>
      </Link>
    </li>
  );
}

NavItem.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default NavItem;
