import React, { useMemo, useCallback } from "react";
import Header from "components/Header";
import TableHeader from "components/TableHeader";
import IndexTable from "components/IndexTable";
import IndexTableWrapper from "components/IndexTableWrapper";

import { getQuotes } from "utils/horizon-api-client";
import { useQuotes } from "hooks/useQuotes";

function Quotes() {
  const {
    fetchMore,
    canFetchMore,
    quotes,
    status,
    searchOptions,
    totalQuotes,
    setSearchTerm,
    searchFieldValue,
  } = useQuotes({ requestData: getQuotes });

  const sortOnStatus = useCallback((rowA, rowB, columnId) => {
    const rowAValue = rowA.values.status.props.status;
    const rowBValue = rowB.values.status.props.status;
    if (rowAValue > rowBValue) return 1;
    if (rowAValue < rowBValue) return -1;
    return 0;
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Quote No ",
        accessor: "quote_no",
      },
      {
        Header: "Customer Name ",
        accessor: "customer_name",
      },
      {
        Header: "Total Doors ",
        accessor: "total_doors",
        className: "v-sm-visible",
      },
      {
        Header: "Total Sale Price ",
        accessor: "total_sale_price",
        className: "v-sm-visible",
      },
      {
        Header: "Sales Person ",
        accessor: "sales_person",
        className: "v-sm-visible",
      },
      {
        Header: "Status ",
        accessor: "status",
        className: "v-sm-visible",
        sortType: sortOnStatus,
      },
      {
        Header: "Last Updated ",
        accessor: "last_updated",
        className: "v-sm-visible",
      },
      {
        Header: "",
        accessor: "dropdown",
        disableSortBy: true,
      },
    ],
    []
  );

  const searchHelperOptions = useMemo(() => {
    const opts = [];
    Object.keys(searchOptions).forEach((optionName) => {
      const option = searchOptions[optionName];
      if (typeof option.displayName !== "undefined") {
        opts.push({
          displayName: option.displayName,
          defaultSearchTerm: option.defaultSearchTerm,
        });
      }
    });
    return opts;
  }, []);

  return (
    <>
      <Header pageTitle="Quotes" />
      <IndexTableWrapper
        onScrollToBottom={fetchMore}
        canFetchMore={
          typeof canFetchMore === "undefined" ? false : canFetchMore
        }
      >
        <TableHeader
          setSearchTerm={setSearchTerm}
          searchFieldValue={searchFieldValue}
          searchHelperOptions={searchHelperOptions}
          resourceName="Quote"
        />
        <IndexTable columns={columns} data={quotes} status={status} />
        <span className="table-footer-label">
          Showing {quotes.length} of {totalQuotes} quotes
        </span>
      </IndexTableWrapper>
    </>
  );
}

export default Quotes;
