import React, { useState } from "react";
import PropTypes from "prop-types";

import { toast } from "react-toastify";

import { useDoor } from "hooks/useDoor";
import { useDoors } from "hooks/useDoors";
import { cloneDoor } from "utils/horizon-api-client";

import "assets/stylesheets/duplicateDoor.css";

function DuplicateDoor({ resourceId, setSelectedUtility, quoteId }) {
  const [qty, setQty] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const { data } = useDoor({ id: resourceId });
  const { refetch } = useDoors({ quoteId });

  async function handleConfirm(e) {
    e.preventDefault();
    if (submitting) {
      return false;
    }

    const confirmed = window.confirm(
      `This will duplicate the door ${qty} times. Continue?`
    );

    if (confirmed) {
      setSubmitting(true);
      const response = await cloneDoor({ id: resourceId, qty: qty });
      setSubmitting(false);

      switch (response.status) {
        case 200:
          toast.success(`Created ${qty} duplicates`);
          setSelectedUtility(null);
          refetch();
          break;
        case 403:
          toast.error("You do not have permissions to perform this action");
          break;
        case 422:
          toast.error("Error duplicating door");
          break;
        case 500:
          toast.error("Internal server error.");
          break;
      }
    }
  }

  function handleOnInputChange(e) {
    setQty(e.target.value);
  }

  return (
    <>
      <span
        id="cancelBtn"
        className={"material-icons"}
        onClick={() => setSelectedUtility(null)}
      >
        cancel
      </span>
      <div id="duplicate-door" className="table-menu">
        <div className={"show dropdown-menu"} id="dropmenu">
          <h1>Duplicate</h1>
          <p>
            Create duplicate line items of{" "}
            <strong>{data.reference ? data.reference : " this door"}</strong>
          </p>
          <div className="row">
            <div className="col col-sm-6">
              <div className="form-group">
                <input
                  type="number"
                  className="form-control"
                  value={qty}
                  onChange={handleOnInputChange}
                />
              </div>
            </div>
            <div className="col col-sm-6">
              <button
                className="btn btn-danger btn-lg"
                disabled={submitting}
                onClick={handleConfirm}
              >
                {submitting ? "Duplicating.." : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

DuplicateDoor.propTypes = {
  resourceId: PropTypes.number.isRequired,
  setSelectedUtility: PropTypes.func.isRequired,
};

export default DuplicateDoor;
