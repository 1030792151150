import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { find } from "lodash";

import { useDoorConfigOptions } from "hooks/useDoorConfigOptions";
import PrehangSectionFields from "components/DoorForm/PrehangSectionFields";

function PrehangSection({
  prehangActive,
  toggleShowPrehangActive,
  markHingesNeeded,
}) {
  const { values, setFieldValue } = useFormikContext();
  const { doorJambHandings } = useDoorConfigOptions();

  const handlePrehangToggle = (e) => {
    e.preventDefault();
    const newVal = !prehangActive;
    setFieldValue("prehang_enabled", String(newVal));
    toggleShowPrehangActive(newVal);
  };

  useEffect(() => {
    const jambHanding = find(
      doorJambHandings,
      (v) => v.value == values.door_jamb_handing_id
    );

    if (typeof jambHanding === "undefined") {
      markHingesNeeded(true);
    } else {
      markHingesNeeded(!!jambHanding.doorstop_hinges);
    }
  }, [values.door_jamb_handing_id, doorJambHandings]);

  return (
    <div className="card mt-0">
      <div className="card-header d-flex align-items-center">
        <h2 className="card-heading mb-0">Prehang Details</h2>
        <a
          href="#"
          className="btn btn-rounded btn-red btn-shadow ml-auto"
          onClick={handlePrehangToggle}
        >
          <span className="material-icons-outlined">
            {prehangActive ? "remove" : "add"}
          </span>
        </a>
      </div>
      {prehangActive && <PrehangSectionFields />}
    </div>
  );
}

PrehangSection.propTypes = {
  prehangActive: PropTypes.bool.isRequired,
  toggleShowPrehangActive: PropTypes.func.isRequired,
  markHingesNeeded: PropTypes.func.isRequired,
};

export default PrehangSection;
