import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import DropdownMenu from "components/DropdownMenu";
import DuplicateQuote from "components/QuotesList/DuplicateQuote";

function QuoteUtilities({ quote }) {
  return (
    <>
      <DropdownMenu resourceId={quote.id}>
        <Link to={`/quotes/${quote.id}/edit`} className="dropdown-item">
          Edit Quote {quote.id}
        </Link>
        <DuplicateQuote resourceId={quote.id} />
      </DropdownMenu>
    </>
  );
}

QuoteUtilities.propTypes = {};

export default QuoteUtilities;
