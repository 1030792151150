import React from "react";
import { Field } from "formik";

import DropdownInput from "components/Formik/DropdownInput";
import Input from "components/Formik/Input";
import { useAddressSuggestions } from "hooks/useAddressSuggestions";
import { useCustomerPolicy } from "policies/useCustomerPolicy";
import { useDynamicAvailableCities } from "hooks/useDynamicAvailableCities";

import "assets/stylesheets/addressSuggestionsList.css";

export default function AddressFields() {
  const { handleFieldChange, addressResultsList } = useAddressSuggestions();
  const { canCreate } = useCustomerPolicy();
  const { availableCities } = useDynamicAvailableCities();

  return (
    <div id="address-suggestions">
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="line-1">Address Line 1</label>
            <Field
              name="address.line_1"
              placeholder="Line 1"
              component={Input}
              disabled={!canCreate}
              afterChange={handleFieldChange}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="line-2">Address Line 2</label>
            <Field
              name="address.line_2"
              placeholder="Line 2"
              component={Input}
              disabled={!canCreate}
            />
          </div>
        </div>
        <div id="address-fields" className="col-md-12">
          {addressResultsList}
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="city">Address City</label>
            <Field
              name="address.city"
              placeholder="City"
              options={availableCities}
              as="select"
              component={DropdownInput}
              disabled={!canCreate}
            ></Field>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="pcode">Address Post Code</label>
            <Field
              name="address.post_code"
              placeholder="Post Code"
              component={Input}
              disabled={!canCreate}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
