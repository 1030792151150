import React, { useMemo, useState, useCallback } from "react";
import { useQuote } from "hooks/useQuote";
import { useParams } from "react-router-dom";

import { exportQuoteReport } from "utils/horizon-api-client";

import "assets/stylesheets/financialSummary.css";

function QuoteFinancialSummary() {
  const { id } = useParams();
  const { data } = useQuote({ id: id });
  const [reportsLoading, setReportsLoading] = useState([]);

  const summaryData = useMemo(() => {
    const totalSalePrice = data.total_sale_price || 0;
    const totalCost = data.total_cost || 0;
    const doorQty = data.total_doors || 0;

    return {
      discount: 0,
      grossMargin: (
        100 *
        ((totalSalePrice - totalCost) / totalSalePrice)
      ).toFixed(2),
      totalSalePrice: totalSalePrice.toFixed(2),
      doorQty: doorQty,
    };
  }, [data]);

  async function exportQuote({ fileName, reportId }) {
    if (reportsLoading.includes(reportId)) {
      return;
    }

    setReportsLoading((prevReports) => [...prevReports, reportId]);
    const response = await exportQuoteReport({ id, fileName, reportId });
    removeLoadingReport(reportId);
    var file = new Blob([response.data], { type: "application/pdf" });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  const removeLoadingReport = useCallback(
    (reportId) => {
      setReportsLoading((prevReports) =>
        prevReports.filter((name) => name !== reportId)
      );
    },
    [reportsLoading]
  );

  const reports = [
    {
      fileName: `Quote ${id} Overview`,
      displayName: "Quote Overview",
      reportId: "overview",
    },
    {
      fileName: `${id} Production Order`,
      displayName: "Production Order",
      reportId: "production_order",
    },
    {
      fileName: `${id} Order Confirmation`,
      displayName: "Order Confirmation",
      reportId: "order_confirmation",
    },
    {
      fileName: `${id} Cutting List`,
      displayName: "Cutting List",
      reportId: "cutting_list",
    },
  ];

  return (
    <>
      <div className="card card-top card-top-big">
        <div className="card-body text-card pad-5rem">
          <div className="row align-items-center" style={{ width: "100%" }}>
            <div className="col-md-3 col-lg-3">
              <h2>Door Quantity: {summaryData.doorQty}</h2>
            </div>
            <div className="col-md-3 col-lg-3">
              <h2>Gross Margin: {summaryData.grossMargin}%</h2>
            </div>
            <div className="col-md-2 col-lg-2">
              <h2>Discount: {summaryData.discount}%</h2>
            </div>
            <div className="col-md-4 col-lg-4">
              <h1>Total Quote: ${summaryData.totalSalePrice}</h1>
            </div>
          </div>
        </div>
      </div>

      <div id="quote-reports-card" className="card">
        <div className="card-header">
          <h2 className="card-heading">Quote Reports</h2>
        </div>
        <div className="card-body">
          <ul className="list-group list-group-flush">
            {reports.map((report) => {
              return (
                <li
                  className="list-group-item"
                  onClick={() =>
                    exportQuote({
                      reportId: report.reportId,
                      fileName: report.fileName,
                    })
                  }
                  key={report.reportId}
                >
                  <span className="material-icons-outlined picture_as_pdf">
                    picture_as_pdf
                  </span>{" "}
                  <span className="text">
                    {reportsLoading.includes(report.reportId)
                      ? "Loading..."
                      : `${report.displayName}.pdf`}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

QuoteFinancialSummary.propTypes = {};

export default QuoteFinancialSummary;
