import React, { useMemo } from "react";
import { toast } from "react-toastify";
import { useParams, Link } from "react-router-dom";

import "assets/stylesheets/editJob.css";

import IndexTable from "components/IndexTable";
import IndexTableWrapper from "components/IndexTableWrapper";
import JobForm from "components/JobForm/JobForm";
import FormHeader from "components/FormHeader";
import { updateJob, getJobQuotes } from "utils/horizon-api-client";
import { useJobPolicy } from "policies/useJobPolicy";
import { useJob } from "hooks/useJob";
import { useQuotes } from "hooks/useQuotes";

function NewJob() {
  const { id } = useParams();
  const { quotes, status: quoteFetchStatus, totalQuotes } = useQuotes({
    requestData: getJobQuotes.bind(null, {
      id: id,
    }),
    nestedResourceId: id,
  });

  const { data, status, refetch } = useJob({ id: id });
  const isLoading = status === "loading";
  const { canCreate } = useJobPolicy();

  async function onSubmit(values, actions) {
    const response = await updateJob({
      id: id,
      values: values,
    });

    actions.setErrors(response.data.errors);
    sendNotification(response);
  }

  function sendNotification(response) {
    switch (response.status) {
      case 200:
        toast.success("Job successfully saved!");
        refetch();
        break;
      case 403:
        toast.error(
          "You do not have permissions to update this job. Please contact an Admin"
        );
        break;
      case 422:
        toast.error("Error saving job.");
        break;
      case 500:
        toast.error("Internal server error.");
        break;
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Quote No ",
        accessor: "quote_no",
      },
      {
        Header: "Customer Name ",
        accessor: "customer_name",
      },
      {
        Header: "Total Doors ",
        accessor: "total_doors",
        className: "v-sm-visible",
      },
      {
        Header: "Total Cost ",
        accessor: "total_cost",
        className: "v-sm-visible",
      },
      {
        Header: "Sales Person ",
        accessor: "sales_person",
        className: "v-sm-visible",
      },
      {
        Header: "Status ",
        accessor: "status",
        className: "v-sm-visible",
      },
      {
        Header: "Last Updated ",
        accessor: "last_updated",
        className: "v-sm-visible",
      },
      {
        Header: "",
        accessor: "dropdown",
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <div id="edit-job">
      <JobForm
        onSubmit={onSubmit}
        initialValues={data}
        isLoading={isLoading}
        header={
          <FormHeader
            title={
              <>
                <Link to="/jobs">
                  <span className="material-icons-outlined">arrow_back</span>
                </Link>
                Edit Job
              </>
            }
            canCreate={canCreate}
          />
        }
      />
      <div className="card">
        <div className="card-header mb-0 mb-sm-5">
          <h2 className="card-heading">Related Quotes</h2>
        </div>
        <IndexTable columns={columns} data={quotes} status={quoteFetchStatus} />
      </div>
    </div>
  );
}

export default NewJob;
