import { Link } from "react-router-dom";
import NavItem from "components/NavItem";
import React from "react";

import { useSidebar } from "contexts/sidebar";
import { useUserPolicy } from "policies/useUserPolicy";

import logo from "assets/images/bd white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Sidebar() {
  const { active, toggleActive } = useSidebar();
  const activeClass = active ? "active" : "";
  const { canView: canViewUsers } = useUserPolicy();

  return (
    <nav id="sidebar" className={activeClass}>
      <a
        href="#"
        className="close-btn"
        id="sidebar-close"
        onClick={toggleActive}
      >
        <FontAwesomeIcon icon={["fas", "times"]} className="close-icon" />
      </a>
      <div className="sidebar-header">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>

      <ul className="list-unstyled sidebar__list">
        <NavItem icon="receipt" text="Quotes" to="/quotes" />
        <NavItem icon="people" text="Customers" to="/customers" />
        <NavItem
          icon="account_circle"
          text="End Customers"
          to="/end-customers"
        />
        <NavItem icon="work_outline" text="Jobs" to="/jobs" />
        {canViewUsers && <NavItem icon="people" text="Users" to="/users" />}
      </ul>
    </nav>
  );
}

Sidebar.propTypes = {};

export default Sidebar;
