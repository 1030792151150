import React, { useMemo } from "react";
import { useQuery } from "react-query";

import { getQuote } from "utils/horizon-api-client";
const { v4: uuidv4 } = require("uuid");

export function useQuote({ id }) {
  const { data, status, refetch } = useQuery(["quote", id], fetchQuoteById);
  const quoteData = useMemo(mapQuoteData, [data]);

  async function fetchQuoteById(key, id) {
    const response = await getQuote({ id: id });
    return response.data;
  }

  function mapQuoteData() {
    if (typeof data === "undefined") return {};

    return {
      status: data.status,
      tagged: data.tagged,
      customer_id: data.customer.id,
      job_id: data.job.id,
      public_notes: data.public_notes,
      order_no: data.order_no,
      quote_no: data.quote_no,
      total_doors: data.total_doors,
      total_cost: data.total_cost_cents / 100,
      total_sale_price: data.total_sale_price_cents / 100,
      internal_notes: data.internal_notes,
      quote_date: data.quote_date,
      files: mapFilesArr(),
      freight_charge: {
        description: data.freight_charge.description,
        cost: data.freight_charge.cost,
      },
      _salesPerson: data.user.name,
    };
  }

  function mapFilesArr() {
    if (typeof data.files === "undefined" || !data.files.length) return [];

    return data.files.map((file) => {
      return {
        uuid: uuidv4(),
        id: file.id,
        name: file.filename,
        download_url: file.download_url,
      };
    });
  }

  return {
    data: quoteData,
    status,
    refetch,
  };
}
