import React from "react";
import PropTypes from "prop-types";

function LoadingOverlay({ isLoading }) {
  return (
    <>
      {isLoading && (
        <div className="spinner-grow text-primary center-overlay" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </>
  );
}

LoadingOverlay.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default LoadingOverlay;
