import React from "react";
import PropTypes from "prop-types";
import { FieldArray, useFormikContext } from "formik";
import { find } from "lodash";

import ResorcinolGlue from "components/DoorForm/ResorcinolGlue";
import { useDoorConfigOptions } from "hooks/useDoorConfigOptions";

function ResorcinolGluesList({ onDelete }) {
  const { values } = useFormikContext();
  const { resorcinolGlues } = useDoorConfigOptions();

  const panelCount = values.resorcinol_glue_ids
    ? values.resorcinol_glue_ids.length
    : 0;

  function getPanelData(id) {
    const data = find(resorcinolGlues, (panel) => panel.value === id);
    return data || {};
  }

  function addPanel(e, arrayHelpers) {
    e.preventDefault();
    arrayHelpers.insert(
      values.resorcinol_glue_ids ? values.resorcinol_glue_ids.length : 0,
      ""
    );
  }

  function removePanel(e, index, arrayHelpers) {
    e.preventDefault();
    if (typeof onDelete === "function") {
      onDelete(values.resorcinol_glue_ids[index]);
    }
    arrayHelpers.remove(index);
  }

  function duplicatePanel(e, index, arrayHelpers) {
    e.preventDefault();
    const newPanel = values.resorcinol_glue_ids[index];
    arrayHelpers.insert(values.resorcinol_glue_ids.length, newPanel);
  }

  return (
    <FieldArray
      name="resorcinol_glue_ids"
      render={(arrayHelpers) => (
        <div className="card mt-0">
          <div className="card-header d-flex align-items-center">
            <h2 className="card-heading mb-0">RG ({panelCount})</h2>
            <a
              href="#"
              className="btn btn-rounded btn-red btn-shadow ml-auto"
              onClick={(e) => {
                addPanel(e, arrayHelpers);
              }}
            >
              <span className="material-icons-outlined">add</span>
            </a>
          </div>

          <div className="card-body">
            {values.resorcinol_glue_ids && values.resorcinol_glue_ids.length > 0
              ? values.resorcinol_glue_ids.map((panel, index) => (
                  <ResorcinolGlue
                    index={index}
                    key={index}
                    onRemove={(e) => {
                      removePanel(e, index, arrayHelpers);
                    }}
                    onDuplicate={(e) => {
                      duplicatePanel(e, index, arrayHelpers);
                    }}
                    objectData={getPanelData(panel)}
                  />
                ))
              : ""}
          </div>
        </div>
      )}
    />
  );
}

ResorcinolGluesList.propTypes = {
  onDelete: PropTypes.func,
};

export default ResorcinolGluesList;
