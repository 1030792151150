import React from "react";
import { ErrorMessage } from "formik";

const Input = ({ field, form, innerRef, afterChange, ...props }) => {
  const meta = form.getFieldMeta(field.name);
  const hasError = typeof meta.error !== "undefined";
  const hasBeenTouched = meta.touched;

  function customOnChange(e) {
    form.handleChange(e);
    if (typeof afterChange === "function") {
      afterChange(e, field);
    }
  }

  return (
    <>
      <input
        className={`form-control ${
          hasError && hasBeenTouched ? "invalid" : ""
        }`}
        {...field}
        {...props}
        onChange={customOnChange}
        ref={innerRef}
      />
      {hasError && hasBeenTouched && (
        <span className="input-error">
          <ErrorMessage name={field.name} />
        </span>
      )}
    </>
  );
};

export default Input;
