import React from "react";
import PropTypes from "prop-types";

function AddressResultsList({ results, onSelect, closeAddressSuggestions }) {
  if (typeof results === "undefined" || results.length == 0) return null;

  const listItems = results.map((result) => (
    <li
      key={result.id}
      className="list-group-item"
      onClick={() => onSelect(result)}
    >
      {result.a}
    </li>
  ));

  return (
    <div id="suggestions-wrapper">
      <div id="address-results-list" className="list-group">
        <ul className="list-group">{listItems}</ul>
      </div>
      <span
        id="close-button"
        className="material-icons-outlined"
        onClick={() => closeAddressSuggestions()}
      >
        disabled_by_default
      </span>
    </div>
  );
}

AddressResultsList.propTypes = {};

export default AddressResultsList;
