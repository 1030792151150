import React from "react";
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import Input from "components/Formik/Input";
import LoadingOverlay from "components/LoadingOverlay";

const UserSchema = Yup.object().shape({
  role: Yup.string().required("Required"),
});

function UserForm({ onSubmit, initialValues, isLoading, header }) {
  return (
    <Formik
      validationSchema={UserSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      <div id="content">
        {header}
        <LoadingOverlay isLoading={isLoading} />

        <div className="card card-top">
          <div className="card-header">
            <h2 className="card-heading">User Details</h2>
          </div>

          <div className="card-body">
            <Form>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="role">Role</label>
                    <div className="select-box">
                      <Field name="role" as="select">
                        <option>admin</option>
                        <option>management</option>
                        <option>team_leader</option>
                        <option>user</option>
                      </Field>
                    </div>
                  </div>
                </div>
                
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <Field name="name" component={Input} disabled={true} />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Field name="email" component={Input} disabled={true} />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Formik>
  );
}

UserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  header: PropTypes.node.isRequired,
};

export default UserForm;
