import React from "react";
import SaveButton from "components/QuoteForm/SaveButton";

function EditQuoteHeaderNav({ isSaving, handleSave }) {
  return (
    <>
      <SaveButton isSaving={isSaving} handleSave={handleSave} />
    </>
  );
}

export default EditQuoteHeaderNav;
