import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTable, useSortBy, useExpanded } from "react-table";

import LoadingOverlay from "components/LoadingOverlay";

const defaultPropGetter = () => ({});

function IndexTable({
  columns,
  status,
  data = [],
  rowProps = () => ({}),
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  onDataUpdate,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      onDataUpdate,
    },
    useSortBy,
    useExpanded
  );

  const isLoading = status === "loading";

  return (
    <div className="containar">
      <div className="content-body">
        <LoadingOverlay isLoading={isLoading} />
        <table
          {...getTableProps()}
          className={`table table-fixed table-hover ${
            isLoading ? "loading-content" : ""
          }`}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: column.className,
                        style: column.style,
                      },
                      getColumnProps(column),
                      getHeaderProps(column),
                      column.getSortByToggleProps(),
                    ])}
                  >
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={["fas", "sort-down"]} />
                      ) : (
                        <FontAwesomeIcon icon={["fas", "sort-up"]} />
                      )
                    ) : (
                      ""
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps(rowProps(row))}>
                  {row.cells.map((cell) => {
                    return (
                      <>
                        <td
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      </>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

IndexTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  getHeaderProps: PropTypes.func,
  getColumnProps: PropTypes.func,
  getRowProps: PropTypes.func,
  getCellProps: PropTypes.func,
  onDataUpdate: PropTypes.func,
  status: PropTypes.string,
};

export default IndexTable;
