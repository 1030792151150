import React, { useRef } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";

function IndexTableWrapper({ onScrollToBottom, children, canFetchMore }) {
  const tableData = useRef(null);
  const onScroll = debounce((e) => {
    const scrollTop =
      tableData.current.scrollTop + tableData.current.clientHeight;
    const scrollTopMax = tableData.current.scrollHeight;
    if (scrollTop > scrollTopMax * 0.7 && canFetchMore) {
      onScrollToBottom();
    }
  }, 100);

  return (
    <div className="content-main card-top index-table" ref={tableData}>
      <div className="contaiiner">{children}</div>
    </div>
  );
}

IndexTableWrapper.propTypes = {
  onScrollToBottom: PropTypes.func.isRequired,
  canFetchMore: PropTypes.bool.isRequired,
  children: PropTypes.array.isRequired,
};

export default IndexTableWrapper;
