import React from "react";
import { toast } from "react-toastify";
import { useHistory, Link } from "react-router-dom";

import CustomerForm from "components/CustomerForm";
import FormHeader from "components/FormHeader";
import { createCustomer } from "utils/horizon-api-client";
import { useCustomerPolicy } from "policies/useCustomerPolicy";

function NewCustomer() {
  const history = useHistory();
  const { canCreate } = useCustomerPolicy();

  const initialValues = {
    company_name: "",
    notes: "",
    contacts: [
      {
        name: "",
        email: "",
        kind: "primary",
      },
      {
        name: "",
        email: "",
        kind: "account",
      },
    ],
  };

  async function onSubmit(values, actions) {
    const response = await createCustomer({
      values: values,
    });

    actions.setErrors(response.data.errors);
    sendNotification(response);

    if (response.status === 200) {
      // Needs to be called twice to clear form persistence.
      // Bug in formik-persist?
      actions.resetForm();
      actions.resetForm();
      redirectToCustomerEditPage(response.data.id);
    }
  }

  function redirectToCustomerEditPage(customerId) {
    history.push(`/customers/${customerId}/edit`);
  }

  function sendNotification(response) {
    switch (response.status) {
      case 200:
        toast.success("Customer successfully saved!");
        break;
      case 403:
        toast.error(
          "You do not have permissions to create a customer. Please contact an Admin"
        );
        break;
      case 422:
        toast.error("Error saving customer.");
        break;
      case 500:
        toast.error("Internal server error.");
        break;
    }
  }

  return (
    <>
      <CustomerForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        isLoading={false}
        shouldPersist={true}
        formName="new-customer-form"
        header={
          <FormHeader
            title={
              <>
                <Link to="/customers">
                  <span className="material-icons-outlined">arrow_back</span>
                </Link>
                New Customer
              </>
            }
            canCreate={canCreate}
          />
        }
      />
    </>
  );
}

export default NewCustomer;
