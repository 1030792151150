import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

function FormHeader({ title, canCreate }) {
  const { submitForm } = useFormikContext();

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <h1 className="navbar-heading ml-0 heading-small">{title}</h1>

          {canCreate && (
            <div className="d-none d-sm-block" id="navbarSupportedContent">
              <ul className="navigation ml-auto">
                <li className="nav-item">
                  <a href="#" className="nav-btn btn-fill" onClick={submitForm}>
                    <span className="material-icons-outlined">save</span>
                    <span>Save</span>
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </nav>

      {canCreate && (
        <div className="button-group d-xs-block d-sm-none card-fixed-nav">
          <a
            href="#"
            className="btn btn-rounded btn-filled btn-red ml-auto"
            onClick={submitForm}
          >
            <span className="material-icons-outlined">save</span>
          </a>
        </div>
      )}
    </>
  );
}

FormHeader.propTypes = {
  title: PropTypes.node.isRequired,
  canCreate: PropTypes.bool.isRequired,
};

export default FormHeader;
