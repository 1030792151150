import React, { useState, useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

function PricingOverview({ doorData }) {
  const { setFieldValue, values } = useFormikContext();
  const [sellPriceInputEnabled, toggleSellPriceInput] = useState(false);
  const priceInput = useRef();

  const sellPrice = useMemo(() => {
    if (values.manual_sell_price > 0) {
      return values.manual_sell_price;
    } else {
      return doorData.calculated_sell_price;
    }
  }, [values, doorData]);

  const manualSellPriceActive = useMemo(() => {
    if (values.manual_sell_price > 0) {
      return true;
    } else {
      return false;
    }
  }, [values]);

  function enableManualSellPrice() {
    toggleSellPriceInput(true);
  }

  function onChangeSellPrice(e) {
    const val = e.target.value;
    setFieldValue("manual_sell_price", val);

    if (val === "") {
      toggleSellPriceInput(false);
      setFieldValue("manual_sell_price", "0");
    }
  }

  function clearManualPricing(e) {
    toggleSellPriceInput(false);
    setFieldValue("manual_sell_price", "0");
  }

  return (
    <p>
      <span>Door Cost: ${doorData.cost_price}</span>
      <br />

      <div id="sell-price" className={manualSellPriceActive ? "edited" : ""}>
        {sellPriceInputEnabled && (
          <div>
            Door Sell Price:{" "}
            <input
              type="number"
              id="sell-price-input"
              ref={priceInput}
              value={values.manual_sell_price}
              onChange={onChangeSellPrice}
              onBlur={() => toggleSellPriceInput(false)}
            />
            <span
              className="material-icons-outlined"
              onClick={clearManualPricing}
            >
              clear
            </span>
          </div>
        )}
        {!sellPriceInputEnabled && (
          <div onClick={enableManualSellPrice}>
            Door Sell Price: ${sellPrice}
          </div>
        )}
      </div>

      <span>Prehang Cost: ${doorData.phang_cost_price}</span>
      <br />
      <span>Prehang Sell Price: ${doorData.phang_sell_price}</span>
    </p>
  );
}

PricingOverview.propTypes = {
  doorData: PropTypes.shape({
    cost_price: PropTypes.number,
    sell_price: PropTypes.number,
    phang_cost_price: PropTypes.number,
    phang_sell_price: PropTypes.number,
  }),
};

export default PricingOverview;
