import React, { useMemo, useEffect, useState } from "react";
import { Field, useFormikContext } from "formik";

import { withBlankOption } from "utils/form-utils";
import { useDoorConfigOptions } from "hooks/useDoorConfigOptions";
import EditableDropdownInput from "components/Formik/EditableDropdownInput";
import DropdownInput from "components/Formik/DropdownInput";
import Input from "components/Formik/Input";
import { find } from "lodash";

function CleanFieldOnUnmount({ ...props }) {
  const { setFieldValue } = useFormikContext();
  useEffect(() => () => setFieldValue(props.name, undefined), []);
  return <Field {...props} />;
}

function PrehangSectionFields() {
  const { setFieldValue, values } = useFormikContext();
  const {
    doorJambTimbers,
    doorJambFacings,
    doorJambHandings,
    doorJambProfiles,
    doorJambSills,
    doorJambFloorClearances,
    doorJambMachiningCodes,
    doorStops,
    doorDetails,
    hinges,
  } = useDoorConfigOptions();

  const doorJambTimberSelectOptions = useMemo(
    () => withBlankOption(doorJambTimbers),
    [doorJambTimbers]
  );
  const hingeSelectOptions = useMemo(() => withBlankOption(hinges), [hinges]);
  const doorJambFacingSelectOptions = useMemo(
    () => withBlankOption(doorJambFacings),
    [doorJambFacings]
  );
  const doorJambHandingSelectOptions = useMemo(() => {
    const availableHandings = doorJambHandings.filter(
      (handing) => handing.door_type === values.door_type
    );
    return withBlankOption(availableHandings);
  }, [doorJambHandings, values.door_type]);

  const doorJambProfileSelectOptions = useMemo(
    () => withBlankOption(doorJambProfiles),
    [doorJambProfiles]
  );
  const doorJambSillSelectOptions = useMemo(
    () => withBlankOption(doorJambSills),
    [doorJambSills]
  );
  const doorJambFloorClearanceSelectOptions = useMemo(
    () => doorJambFloorClearances,
    [doorJambFloorClearances]
  );
  const doorJambMachiningCodeSelectOptions = useMemo(
    () => withBlankOption(doorJambMachiningCodes),
    [doorJambMachiningCodes]
  );
  const doorStopOptions = useMemo(() => withBlankOption(doorStops), [
    doorStops,
  ]);
  const booleanDropdown = [
    { label: "True", value: "true" },
    { label: "False", value: "false" },
  ];
  const fjclOptions = [
    { label: "", value: "" },
    { label: "FJ", value: "FJ" },
    { label: "CL", value: "CL" },
  ];

  const [fjclEnabled, setFjClStatus] = useState(false);
  useEffect(() => {
    const timber = find(
      doorJambTimbers,
      (v) => v.value === values.door_jamb_timber_id
    );

    if (typeof timber !== "undefined") {
      if (timber.fj_cl === "A") {
        setFjClStatus(true);
        if (values.manual_fj_cl === "") {
          setFieldValue("manual_fj_cl", "");
        }
      } else if (timber.fj_cl === "B") {
        setFjClStatus(false);
        setFieldValue("manual_fj_cl", "CL");
      } else if (timber.fj_cl === "C") {
        setFjClStatus(false);
        setFieldValue("manual_fj_cl", "FJ");
      }
    }
  }, [doorJambTimbers, values.door_jamb_timber_id]);

  const [hingeFaceEnabled, setHingeFaceStatus] = useState(false);
  useEffect(() => {
    const jambProfile = find(
      doorJambProfiles,
      (v) => v.value === values.door_jamb_profile_id
    );

    if (typeof jambProfile !== "undefined") {
      if (jambProfile.code === "SLM") {
        setHingeFaceStatus(false);
        setFieldValue("manual_reveal_hinge_face", 11);
        setFieldValue("manual_reveal_non_hinge_face", 11);
      } else {
        setHingeFaceStatus(true);
      }
    }
  }, [doorJambProfiles, values.door_jamb_profile_id]);

  const [defaultValuesSet, markDefaultValuesSet] = useState(false);
  useEffect(() => {
    if (
      doorJambFloorClearances.length &&
      !defaultValuesSet &&
      !values.door_jamb_floor_clearance_id
    ) {
      const defaultClearanceId = find(doorJambFloorClearances, (obj) =>
        obj.label.includes("0 - 25MM")
      );
      if (defaultClearanceId) {
        setFieldValue("door_jamb_floor_clearance_id", defaultClearanceId.value);
      }
      markDefaultValuesSet(true);
    }
  }, [doorJambFloorClearances]);

  return (
    <div className="card-body">
      <div className="form-row">
        <div className="col-md-3 col-sm-6">
          <label htmlFor="standard_jamb">Standard Jamb</label>
          <CleanFieldOnUnmount
            name="standard_jamb"
            component={DropdownInput}
            options={booleanDropdown}
            as="select"
          >
            <option value=""></option>
          </CleanFieldOnUnmount>
        </div>
        <div className="col-md-3 col-sm-6">
          <label htmlFor="ph_galv_steel_frame">PH Galv Steel Frame</label>
          <CleanFieldOnUnmount
            name="ph_galv_steel_frame"
            component={DropdownInput}
            options={booleanDropdown}
            as="select"
          >
            <option value=""></option>
          </CleanFieldOnUnmount>
        </div>
        <div className="col-md-3 col-sm-6">
          <label htmlFor="number_of_sides">Number of Sides</label>
          <CleanFieldOnUnmount name="number_of_sides" component={Input} />
        </div>
        <div className="col-md-3 col-sm-6">
          <label htmlFor="number_of_hinges">Number of Hinges</label>
          <CleanFieldOnUnmount name="number_of_hinges" component={Input} />
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label htmlFor="door_jamb_facing_id">Jamb Handing</label>
            <CleanFieldOnUnmount
              name="door_jamb_handing_id"
              component={DropdownInput}
              options={doorJambHandingSelectOptions}
              as="select"
            >
              <option value=""></option>
            </CleanFieldOnUnmount>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <label htmlFor="jamb_thickness">Jamb Thickness</label>
          <CleanFieldOnUnmount name="jamb_thickness" component={Input} />
        </div>
        <div className="col-md-3 col-sm-6">
          <label htmlFor="discount_factor_phang">Discount Factor PHang</label>
          <CleanFieldOnUnmount name="discount_factor_phang" component={Input} />
        </div>
        <div className="col-md-3 col-sm-6">
          <label htmlFor="manual_machining_code">Manual Machining Code</label>
          <CleanFieldOnUnmount
            name="manual_machining_code"
            component={DropdownInput}
            options={doorJambMachiningCodeSelectOptions}
            as="select"
          >
            <option value=""></option>
          </CleanFieldOnUnmount>
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-3 col-sm-6">
          <label htmlFor="wall_stud_width">Wall Stud Width</label>
          <CleanFieldOnUnmount name="wall_stud_width" component={Input} />
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label htmlFor="door_jamb_timber_id">Jamb Timber</label>
            <CleanFieldOnUnmount
              name="door_jamb_timber_id"
              component={DropdownInput}
              options={doorJambTimberSelectOptions}
              as="select"
            >
              <option value=""></option>
            </CleanFieldOnUnmount>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <label htmlFor="manual_fj_cl">FJ/CL</label>
          <CleanFieldOnUnmount
            name="manual_fj_cl"
            component={DropdownInput}
            options={fjclOptions}
            disabled={!fjclEnabled}
            as="select"
          >
            <option value=""></option>
          </CleanFieldOnUnmount>
        </div>

        <div className="col-md-3 col-sm-6">
          <label htmlFor="manual_door_stop_part_code">Manual Door Stop</label>
          <CleanFieldOnUnmount
            name="manual_door_stop_part_code"
            component={DropdownInput}
            options={doorStopOptions}
            as="select"
          >
            <option value=""></option>
          </CleanFieldOnUnmount>
        </div>
      </div>

      <div className="form-row mt-5">
        <div className="col-md-4 col-sm-12">
          <div className="form-group">
            <label htmlFor="hinge_face_gib_lining_thickness">
              Hinge Face Gib Lining Thickness
            </label>
            <CleanFieldOnUnmount
              name="hinge_face_gib_lining_thickness"
              component={Input}
            />
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
          <div className="form-group">
            <label htmlFor="non_hinge_face_gib_lining_thickness">
              Non-Hinge Face Gib Lining Thickness
            </label>
            <CleanFieldOnUnmount
              name="non_hinge_face_gib_lining_thickness"
              component={Input}
            />
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
          <label htmlFor="manual_hinge_part_code">Hinge</label>
          <CleanFieldOnUnmount
            name="manual_hinge_part_code"
            component={DropdownInput}
            options={hingeSelectOptions}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-6 col-sm-6">
          <label htmlFor="manual_reveal_hinge_face">Reveal Hinge Face</label>
          <CleanFieldOnUnmount
            name="manual_reveal_hinge_face"
            component={Input}
            disabled={!hingeFaceEnabled}
          />
        </div>
        <div className="col-md-6 col-sm-6">
          <label htmlFor="manual_reveal_hinge_face">
            Reveal Non-Hinge Face
          </label>
          <CleanFieldOnUnmount
            name="manual_reveal_non_hinge_face"
            component={Input}
            disabled={!hingeFaceEnabled}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label htmlFor="door_jamb_facing_id">Jamb Facing</label>
            <CleanFieldOnUnmount
              name="door_jamb_facing_id"
              component={DropdownInput}
              options={doorJambFacingSelectOptions}
              as="select"
            >
              <option value=""></option>
            </CleanFieldOnUnmount>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label htmlFor="door_jamb_profile_id">Jamb Profile</label>
            <CleanFieldOnUnmount
              name="door_jamb_profile_id"
              component={DropdownInput}
              options={doorJambProfileSelectOptions}
              as="select"
            >
              <option value=""></option>
            </CleanFieldOnUnmount>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label htmlFor="door_jamb_sill_id">Jamb Sill</label>
            <CleanFieldOnUnmount
              name="door_jamb_sill_id"
              component={DropdownInput}
              options={doorJambSillSelectOptions}
              as="select"
            >
              <option value=""></option>
            </CleanFieldOnUnmount>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label htmlFor="door_jamb_floor_clearance_id">
              Jamb Floor Clearance
            </label>
            <CleanFieldOnUnmount
              name="door_jamb_floor_clearance_id"
              component={DropdownInput}
              options={doorJambFloorClearanceSelectOptions}
              as="select"
            >
              <option value=""></option>
            </CleanFieldOnUnmount>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-12 col-sm-12">
          <div className="form-group">
            <label htmlFor="prehang_details">Prehang Details</label>
            <CleanFieldOnUnmount
              name="prehang_details"
              component={EditableDropdownInput}
              options={doorDetails}
              as="textarea"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

PrehangSectionFields.propTypes = {};

export default PrehangSectionFields;
