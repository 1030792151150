import React, { useMemo } from "react";
import { useQuery } from "react-query";

import { getDoor } from "utils/horizon-api-client";

export function useDoor({ id }) {
  const { data, status, refetch } = useQuery(["door", id], fetchDoorById);
  const doorData = useMemo(mapDoorData, [data]);

  async function fetchDoorById(key, id) {
    const response = await getDoor({ id: id });
    return response.data;
  }

  function mapDoorData() {
    if (typeof data === "undefined") return {};

    return {
      id: data.id,
      quote_id: data.quote_id,
      door_type: data.door_type,
      height: data.height || 0,
      width: data.width || 0,
      width_pair: data.width_pair || 0,
      thickness: data.thickness || 0,
      number_of_clashings: data.number_of_clashings || 0,
      discount_factor: data.discount_factor || 0,
      contribution_factor: data.contribution_factor || 0,
      clashing_1_thickness: data.clashing_1_thickness || 0,
      clashing_2_thickness: data.clashing_2_thickness || 0,

      cost_price: data.cost_price || 0,
      sell_price: data.sell_price || 0,
      calculated_sell_price: data.calculated_sell_price || 0,
      manual_sell_price: data.manual_sell_price || 0,
      phang_cost_price: data.phang_cost_price || 0,
      phang_sell_price: data.phang_sell_price || 0,

      core_type_id: data.core_type_id || "",
      skin_code_id: data.skin_code_id || "",
      clashing_timber_id: data.clashing_timber_id || "",
      meeting_stile_id: data.meeting_stile_id || "",
      manual_fj_cl: data.manual_fj_cl || "",
      manual_hinge_part_code: data.manual_hinge_part_code || "",
      manual_door_stop_part_code: data.manual_door_stop_part_code || "",
      isChild: data.parent_id,

      hinge_face_gib_lining_thickness:
        data.hinge_face_gib_lining_thickness || 0,
      non_hinge_face_gib_lining_thickness:
        data.non_hinge_face_gib_lining_thickness || 0,

      door_jamb_timber_id: data.door_jamb_timber_id || "",
      door_jamb_facing_id: data.door_jamb_facing_id || "",
      door_jamb_handing_id: data.door_jamb_handing_id || "",
      door_jamb_profile_id: data.door_jamb_profile_id || "",
      door_jamb_sill_id: data.door_jamb_sill_id || "",
      door_jamb_floor_clearance_id: data.door_jamb_floor_clearance_id || "",

      ph_galv_steel_frame:
        data.ph_galv_steel_frame == null
          ? null
          : String(data.ph_galv_steel_frame) || "false",
      discount_factor_phang: data.discount_factor_phang || 0.0,
      number_of_hinges: data.number_of_hinges || 0,
      standard_jamb:
        data.standard_jamb == null
          ? null
          : String(data.standard_jamb) || "false",
      cropping_cyl_or_mortice: data.cropping_cyl_or_mortice || "", // not sure if used
      handing_large_leaf: data.handing_large_leaf || "", // not sure if used
      jamb_thickness: data.jamb_thickness || 0,
      manual_machining_code: data.manual_machining_code || "",
      number_of_sides: data.number_of_sides || 0,
      wall_stud_width: data.wall_stud_width || 0,
      manual_reveal_hinge_face: data.manual_reveal_hinge_face || 0,
      manual_reveal_non_hinge_face: data.manual_reveal_non_hinge_face || 0,
      prehang_enabled: data.prehang_enabled,
      door_details: data.door_details || "",
      prehang_details: data.prehang_details,

      serial: data.serial || "",
      reference: data.reference || "",
      vision_panel_ids: visionPanelsArray(),
      grille_opening_ids: grilleOpeningArray(),
      resorcinol_glue_ids: resorcinolGlueArray(),
      door_door_extras: doorDoorExtraArray(),
    };
  }

  function visionPanelsArray() {
    if (typeof data === "undefined" || data.vision_panels.length < 1) return [];

    return data.vision_panels.map((panel) => {
      return panel.id;
    });
  }

  function grilleOpeningArray() {
    if (typeof data === "undefined" || data.grille_openings.length < 1)
      return [];

    return data.grille_openings.map((obj) => {
      return obj.id;
    });
  }

  function resorcinolGlueArray() {
    if (typeof data === "undefined" || data.resorcinol_glues.length < 1)
      return [];

    return data.resorcinol_glues.map((obj) => {
      return obj.id;
    });
  }

  function doorDoorExtraArray() {
    if (typeof data === "undefined" || data.door_door_extras.length < 1)
      return [];

    return data.door_door_extras.map((obj) => {
      return {
        id: obj.id,
        door_extra_id: obj.door_extra_id,
        qty: obj.qty,
      };
    });
  }

  return {
    data: doorData,
    status,
    refetch,
  };
}
