import React from "react";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

import DoorForm from "components/DoorForm/DoorForm";
import DoorFormHeaderNav from "components/DoorForm/DoorFormHeaderNav";
import { useQuote } from "hooks/useQuote";
import { createDoor } from "utils/horizon-api-client";

function NewDoor() {
  const history = useHistory();
  const { id } = useParams();
  const { data: quoteData, status, refetch } = useQuote({ id: id });

  const initialValues = {
    door_type: "",
    height: 0,
    width: 0,
    width_pair: 0,
    thickness: 0,
    skin_code_id: "",
    clashing_strip: "",
    meeting_stile: "",
    reference: "",
  };

  async function onSubmit(values, actions) {
    const response = await createDoor({
      quoteId: id,
      values: values,
    });

    actions.setErrors(response.data.errors);
    sendNotification(response);

    if (response.status === 200) {
      // Needs to be called twice to clear form persistence.
      // Bug in formik-persist?
      actions.resetForm();
      actions.resetForm();

      redirectToDoorEditPage(response.data.id);
    }
  }

  function redirectToDoorEditPage(doorId) {
    history.push(`/doors/${doorId}/edit`);
  }

  function sendNotification(response) {
    switch (response.status) {
      case 200:
        toast.success("Door successfully saved!");
        break;
      case 403:
        toast.error(
          "You do not have permissions to create a door. Please contact an Admin"
        );
        break;
      case 422:
        toast.error("Error saving door.");
        break;
      case 500:
        toast.error("Internal server error.");
        break;
    }
  }

  return (
    <>
      <DoorForm
        onSubmit={onSubmit}
        headerRightNav={
          <DoorFormHeaderNav isSaving={false} handleSave={onSubmit} />
        }
        quoteId={id}
        initialValues={initialValues}
        isLoading={false}
        shouldPersist={true}
        formName={`quote-${id}-new-door-form`}
      />
    </>
  );
}

export default NewDoor;
